import { Button, SearchInput, Table } from "components";
import useBulkApproval from "hooks/useBulkApproval";
import useGetListTable from "hooks/useGetListTable";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { BsCheckCircle } from "react-icons/bs";
import { HiOutlineEye } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router";
import { ACTIVITY_REPORT } from "services";

export const ReviewTable = ({ status }) => {
  const navigate = useNavigate();

  const [params, setParams] = useState({});

  const {
    isLoading,
    data: dataSource,
    refetch,
    setOrderBy,
    setOrderDirection,
    setKeyword,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useGetListTable(
    `activity-report-0`,
    ACTIVITY_REPORT.getLaporanAktifitas,
    {
      ...params,
      status,
    }
  );

  const {
    renderModal,
    approveModal,
    setApproveModal,
    declineModal,
    setDeclineModal,
    type,
    setType,
    id,
    setId,
    methods,
  } = useBulkApproval(ACTIVITY_REPORT.putBulkStatusLaporan, refetch);

  useEffect(() => {
    setPage(1);
  }, [status]);

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(dataSource?.paginator?.page) *
            parseInt(dataSource?.paginator?.limit) -
          parseInt(dataSource?.paginator?.limit) +
          index +
          1;

        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "cfp.proposal_title",
      title: "Judul Proposal",
      dataIndex: "cfp.proposal_title",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("proposal_title");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "author_name",
      title: "Pengaju",
      dataIndex: "author_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "cfp.program_name",
      title: "Nama Program",
      dataIndex: "cfp.program_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("program_name");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "submited_date",
      title: "Tanggal Pelaksanaan",
      dataIndex: "submited_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : ""}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      hasCheckbox: true,
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: false,
      sorter: (data, direction) => {
        setOrderBy(data);
        setOrderDirection(direction);
      },
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() =>
              navigate(`/program-physical-and-financial/detail/${data.id}`)
            }
          />
          <BsCheckCircle
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              setApproveModal(true);
              setType(1);
              id.push(data.id);

              setId(id);
            }}
          />
          <IoMdClose
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              setDeclineModal(true);
              setType(2);
              id.push(data.id);

              setId(id);
            }}
          />
        </div>
      ),
    },
  ];

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  return (
    <>
      <div className="flex gap-2">
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
          <div className="flex flex-1 gap-2">
            <Button
              onClick={() => {
                setDeclineModal(true);
                setType(2);
              }}
              className="w-1/2 border-white bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
              disabled={id.length === 0}
            >
              Tolak
            </Button>
            <Button
              onClick={() => {
                setApproveModal(true);
                setType(1);
              }}
              className="w-1/2 text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              disabled={id.length === 0}
            >
              Disetujui
            </Button>
          </div>
        </div>
      </div>

      <div className="flex w-full p-0">
        <div className="flex w-full card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <Table
              bordered
              stripped
              className="mb-4"
              columns={tableColumns}
              dataSource={dataSource?.result}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={dataSource?.paginator}
              onIdSelected={(data) => setId(data)}
            />
          </div>
        </div>
      </div>
      <FormProvider {...methods}>{renderModal()}</FormProvider>
    </>
  );
};
