import config from "app/config";
import axiosInstance from "app/interceptors";

export const getGroupList = (params, signal) => {
  return axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/group`, { params, signal });
}

export const getGroupDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/group/${id}`);
  return data;
}

export const getGroupMember = async (params, signal) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/group/${params?.id ?? ""}/debtor`, { params: params?.payload, signal });
  return data;
}