import { Table } from "components";
import { useMemo } from "react";

const SourceOfFunds = () => {
  const data = useMemo(() => {
    return [
      {
        nominal: "Rp. XXX.XXX.XXX",
        mutation: "Masuk",
        source: "APBN",
        date: "20 Februari 2023",
      },
      {
        nominal: "Rp. XXX.XXX.XXX",
        mutation: "Masuk",
        source: "APBN",
        date: "20 Februari 2023",
      },
      {
        nominal: "Rp. XXX.XXX.XXX",
        mutation: "Masuk",
        source: "APBN",
        date: "20 Februari 2023",
      },
      {
        nominal: "Rp. XXX.XXX.XXX",
        mutation: "Masuk",
        source: "APBN",
        date: "20 Februari 2023",
      },
      {
        nominal: "Rp. XXX.XXX.XXX",
        mutation: "Masuk",
        source: "APBN",
        date: "20 Februari 2023",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 5,
      previous_pages: null,
      next_pages: null,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "nominal",
        title: "Nominal",
        dataIndex: "nominal",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "mutation",
        title: "Mutasi",
        dataIndex: "mutation",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "source",
        title: "Tujuan/Sumber",
        dataIndex: "source",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "date",
        title: "Tanggal",
        dataIndex: "date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
    ];
  }, []);

  return (
    <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
      <div className="card-body p-3">
        <div className="space-y-4">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={data}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={paginator}
          />
        </div>
      </div>
    </div>
  );
};

export default SourceOfFunds;
