import React from "react";
import { AuthConsumer } from "providers/authProvider";
import { Spinner } from "components";

const LogoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return (
        <div className="relative min-h-screen">
          <Spinner />
        </div>
      );
    }}
  </AuthConsumer>
);

export default LogoutCallback;