import { map } from "lodash";
import React from "react";

const ActivityDocument = () => {
  return (
    <div className="space-y-8">
      {map(Array(3), (value, key) => (
        <div
          key={key}
          className="flex flex-col lg:flex-row space-y-8 lg:space-x-8 lg:space-y-8"
        >
          <img
            src="https://placeimg.com/80/80/people"
            alt="people"
            className="w-40 h-40"
          />
          <div className="flex space-x-4">
            <div className="space-y-4">
              <div className="text-sm font-[700]">Alamat :</div>
              <div className="text-sm font-[700]">Longitude :</div>
              <div className="text-sm font-[700]">Latitude :</div>
            </div>
            <div className="space-y-4">
              <div className="text-sm font-[400]">
                Jl. Detail Alamat RT 001 RW 002, Kel. Bandung Kec. Bandung, Kota
                Bandung, Jawa Barat
              </div>
              <div className="text-sm font-[400]">1p827378126918732</div>
              <div className="text-sm font-[400]">1p827378126918732</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActivityDocument;
