import { Breadcrumbs, TitleText } from "components";
import { map } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ReviewTable } from "./reviewTable";

const ProgramPhysicalAndFinancial = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [keyword, setKeyword] = useState(undefined);

  const links = [
    {
      label: "Laporan Kegiatan Fisik & Keuangan",
    },
  ];

  const tabs = [
    {
      label: "Perlu Ditinjau",
    },
    {
      label: "Disetujui",
    },
    {
      label: "Ditolak",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="flex items-center my-3 justify-between">
        <TitleText className="flex-1">Daftar Laporan</TitleText>
      </div>

      {/* Tabs */}
      <div className="tabs">
        {map(tabs, (tab, key) => (
          <a
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
              key === active
                ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                : "border-b"
            }`}
          >
            {tab.label}
          </a>
        ))}
      </div>

      <ReviewTable status={active} />
    </div>
  );
};

export default ProgramPhysicalAndFinancial;
