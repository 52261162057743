import React from "react";

const Label = ({ children, required }) => {
  return (
    <div className="text-gray-800 font-semibold text-sm">
      {children}&nbsp;{required ? <span className="text-error-500">*</span> : null}
    </div>
  );
};

export default Label;
