import { Table, TitleText } from "components";
import { BsEye } from "react-icons/bs";

const RealizationTable = () => {
  const dataSource = [
    {
      deptor_name: "Reyhan Fikran",
      monev_result: "Lancar",
    },
    {
      deptor_name: "Reyhan Fikran",
      monev_result: "Kurang Lancar",
    },
    {
      deptor_name: "Reyhan Fikran",
      monev_result: "Macet",
    },
    {
      deptor_name: "Reyhan Fikran",
      monev_result: "Tidak Lancar",
    },
    {
      deptor_name: "Reyhan Fikran",
      monev_result: "Macet",
    },
  ];

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          // parseInt(paginator?.page) * parseInt(paginator?.limit) -
          // parseInt(paginator?.limit) +
          index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "deptor_name",
      title: "Nama Debitur",
      dataIndex: "deptor_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "monev_result",
      title: "Hasil Monev",
      dataIndex: "monev_result",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      // width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex ">
          <BsEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {}}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex w-full p-0">
      <div className="flex w-full card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="flex justify-between card-header p-3 gap-2">
          <TitleText className="flex-1">
            Daftar Debitur Proses Realisasi
          </TitleText>
        </div>
        <div className="card-body p-3">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={[]}
            // isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={[]}
          />
        </div>
      </div>
    </div>
  );
};

export default RealizationTable;
