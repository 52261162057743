import React from "react";

const GoodIntention = () => {
  return (
    <div className="space-y-4">
      <div className="font-[400] text-sm">
        {
          "Nilai Akhir Indikator: Baik (mudah dihubungi dan memberikan informasi yang sesuai), Kurang Baik (mudah dihubungi namun memberikan informasi tidak sesuai), Tidak Baik (sulit dihubungi)"
        }
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Kemudahan saat dihubungi :</div>
          <div className="text-sm font-[400]">Mudah</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">
            Memberikan informasi yang sesuai :
          </div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
      </div>
    </div>
  );
};

export default GoodIntention;
