import SparkIcon from "../../assets/spark.svg";
import DocIcon from "../../assets/doc.svg";
import { useMutationNotification } from "hooks/useNotification";

const NotificationItem = ({
  title,
  description,
  created_at,
  readed_at,
  type,
  uuid,
  onHandleRead,
}) => {
  const handleReadNotification = useMutationNotification();

  function timeDifferenceToReadable(date) {
    const now = new Date();
    const past = new Date(date);
    const diffInSeconds = Math.floor((now - past) / 1000);

    if (diffInSeconds < 60) {
      return "Beberapa detik yang lalu";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} menit yang lalu`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} jam yang lalu`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} hari yang lalu`;
    }
  }

  return (
    <div
      className={`hover:bg-[#F7FFFA] p-4 cursor-pointer flex flex-row border-b-2 border-gray-200 w-full gap-5 
        `}
      onClick={() => handleReadNotification(uuid)}
    >
      <img src={type.toLowerCase() == "daprog" ? SparkIcon : DocIcon} />
      <div className={`flex flex-col gap-1 items-baseline w-full `}>
        <div className="flex w-full justify-between">
          <h3 className="text-sm font-bold">{title}</h3>
          {!readed_at && (
            <div className="rounded-full bg-error-600 w-2 h-2"></div>
          )}
        </div>
        <p className="text-sm font-normal">{description}</p>
        <span className="text-xs text-primary-700">
          {timeDifferenceToReadable(created_at)}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
