import Indicator from "pages/Evaluate/Indicator"

const indicatorRoutes = [
  {
    routeType: "public",
    path: "/evaluate-indicator",
    element: Indicator,
    exact: true,
    type: "canView",
    key: "evaluate-indicator",
  },
]

export default indicatorRoutes