import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { random } from "lodash";

const LineChart = () => {
  const options = {
    chart: {
      backgroundColor: "#F9FAFB",
      type: "spline",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    yAxis: {
      title: { enabled: false },
      tickInterval: 10,
    },
    series: [
      {
        name: "Rencana Lelang",
        data: [random(0, 150), random(0, 150), random(0, 150), random(0, 150)],
        color: "#00BD52",
      },
      {
        name: "Serapan",
        data: [random(0, 150), random(0, 150), random(0, 150), random(0, 150)],
        color: "#E34F13",
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default LineChart;
