import moment from "moment";
import React from "react";

const ProgramInformation = ({
  data
}) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4">
            <div className="flex items-center gap-3">
              <div className="text-sm font-bold">Nama Program :</div>
              <div className="text-sm font-normal">{data?.program_name ?? '-'}</div>
            </div>
            <div className="flex items-center gap-3">
              <div className="text-sm font-bold">Tanggal Submit :</div>
              <div className="text-sm font-normal">
                {" "}
                {data?.submit_date
                  ? moment(data?.submit_date).format("DD/MM/YYYY")
                  : "-"}
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="text-sm font-bold">Status :</div>
              <div className="text-sm font-normal">{data?.status}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramInformation;
