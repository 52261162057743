import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const MainPage = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   navigate("/deptor")
  // }, []);

  return (
    <div>Selamat Datang</div>
  );
}

export default MainPage;