import { map } from "lodash";
import React from "react";

const ActivityDocument = () => {
  return (
    <div className="space-y-8">
      {map(Array(3), (value, key) => (
        <div
          key={key}
          className="flex flex-col lg:flex-row space-y-8 lg:space-x-8 lg:space-y-8"
        >
          <img
            src="https://source.unsplash.com/320x320/?portrait"
            alt="people"
            className="w-40 h-40"
          />
          <div className="flex flex-col w-full gap-2">
            <div className="flex text-sm gap-2">
              <p className="font-bold">Alamat:</p>
              <p className="font-[400] text-truncate">
                Jl. Detail Alamat RT 001 RW 002, Kel. Bandung Kec. Bandung, Kota
                Bandung, Jawa Barat
              </p>
            </div>
            <div className="flex text-sm gap-2">
              <p className="font-bold">Longitude:</p>
              <p className="font-[400]">1p827378126918732</p>
            </div>
            <div className="flex text-sm gap-2">
              <p className="font-bold">Latitude:</p>
              <p className="font-[400]">1p827378126918732</p>
            </div>
            <div className="flex text-sm gap-2">
              <p className="font-bold">Tanggal:</p>
              <p className="font-[400]">9:00, 12 Januari 2022</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ActivityDocument;
