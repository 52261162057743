import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";

let NEXT_PAGE = null;

const CustomSelect = ({
  optionsData,
  name,
  onChange,
  value,
  placeholder = "Cari ...",
  ...props
}) => {
  const methods = useFormContext();
  const [options, setOptions] = useState([]);

  const SelectStyles = useMemo(
    () => ({
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: "14px",
      }),
      input: (styles) => ({
        ...styles,
        border: "0px",
        borderColor: "unset",
        input: {
          "-webkit-box-shadow": "none !important",
        },
      }),
      control: (styles) => ({
        ...styles,
        borderColor: "#EAECF0",
        ":hover": {
          ...styles[":hover"],
          borderColor: "#EAECF0",
        },
        ":focus": {
          ...styles[":focus"],
          borderColor: "#EAECF0",
        },
        boxShadow: "none",
        borderRadius: "6px",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#98A2B3",
      }),
      indicatorContainer: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? "#98A2B3" : "#98A2B3",
        ":hover": {
          ...styles[":hover"],
          color: "#98A2B3",
        },
      }),
      option: (styles, { data, isSelected, isFocused, isDisabled }) => ({
        ...styles,
        cursor: isDisabled ? "not-allowed" : "auto",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#01A24A"
          : isFocused
          ? "#EEFFF4"
          : undefined,
        color: isDisabled
          ? "hsl(0, 0%, 80%)"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#98A2B3"
          : data.color,
      }),
    }),
    []
  );

  useEffect(() => {
    if (optionsData.length) {
      setOptions(
        optionsData.map((data) => ({
          value: data.value,
          label: data.label,
        }))
      );
    }
  }, [optionsData]);

  if (name && methods?.control) {
    return (
      <Controller
        control={methods?.control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
          <div className="flex flex-col">
            <ReactSelect
              {...props}
              placeholder={placeholder}
              value={value}
              options={options}
              styles={SelectStyles}
              closeMenuOnScroll={false}
              onChange={onChange}
              classNamePrefix="my-select"
              menuPlacement="auto"
            />
            {invalid && <div className="text-xs text-error-600 mt-2">{error?.message}</div>}
          </div>
        )}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      placeholder={placeholder}
      value={value}
      options={options}
      styles={SelectStyles}
      closeMenuOnScroll={false}
      onChange={onChange}
      classNamePrefix="my-select"
      menuPlacement="auto"
    />
  );
};

export default React.memo(CustomSelect);
