import React from "react";
import numberToText from "number-to-text";
import "number-to-text/converters/en-us";

const Beneficiaries = ({ data }) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div>
              <div className="text-sm font-[700]">Kelompok :</div>
              <div className="text-sm font-[400]">
                {data?.indigenous_communities ?? "-"}
              </div>
              <div className="text-sm font-[700]">Deskripsi Kelompok:</div>
              <div className="text-sm font-[400]">
                {data?.indigenous_communities_description ?? "-"}
              </div>
              <div className="text-sm font-[700]">Status :</div>
              <div className="text-sm font-[400]">{data?.status ?? "-"}</div>
            </div>

            <div>
              <div className="text-sm font-[700]">
                Jumlah Penerima Manfaat :
              </div>
              <div className="text-sm font-[400]">
                {data?.total_beneficiaries ?? "-"}
              </div>
              <div className="text-sm font-[700]">Jumlah Laki-Laki:</div>
              <div className="text-sm font-[400]">{data?.total_man ?? "-"}</div>
              <div className="text-sm font-[700]">Jumlah Perempuan :</div>
              <div className="text-sm font-[400]">
                {data?.total_woman ?? "-"}
              </div>
            </div>

            <div>
              <div className="text-sm font-[700]">
                Hukum Tradisional Komunitas :
              </div>
              <div className="text-sm font-[400]">
                {data?.traditional_law_communities ?? "-"}
              </div>
              <div className="text-sm font-[700]">
                Deskripsi Hukum Tradisional Komunitas:
              </div>
              <div className="text-sm font-[400]">
                {data?.traditional_law_communities_description ?? "-"}
              </div>
            </div>

            <div>
              <div className="text-sm font-[700]">Komunitas Rentan :</div>
              <div className="text-sm font-[400]">
                {data?.vulnerable_communities ?? "-"}
              </div>
              <div className="text-sm font-[700]">Deskripsi Komunitas Rentan :</div>
              <div className="text-sm font-[400]">
                {data?.vulnerable_communities_description ?? "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficiaries;
