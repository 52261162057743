import { Button } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import deleteIcon from "../../../assets/delete.svg";

const ConfirmationModal = ({ open, onClose, onSubmit }) => {
  return (
    <AnimatePresence mode="wait">
      {open && (
        <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
          <motion.div
            key="modal-confirmation"
            className="bg-white rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <div className="flex flex-col p-6 space-y-4 gap-2">
              <div className="flex-1 flex items-center justify-between">
                <img src={deleteIcon} />
                <CgClose
                  className="text-xl cursor-pointer"
                  onClick={() => onClose(false)}
                />
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-lg width-[473px]">
                  Apakah Anda yakin ingin menghapus data tersebut?
                </span>
                <span className="font-normal text-sm text-primary-25">
                  Tindakan ini tidak dapat dibatalkan.
                </span>
              </div>
              <hr />
              <div className="flex-1 flex items-center space-x-4">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1 border-0"
                  onClick={() => onClose(false)}
                >
                  Batal
                </Button>
                <Button
                  type="button"
                  className="text-white border-[#BC3A12] bg-[#BC3A12] hover:bg-[#BC3A12] hover:border-[#BC3A12] flex-1"
                  onClick={onSubmit}
                >
                  Hapus
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ConfirmationModal;
