import React from "react";
import classNames from "classnames";
import { getValueByPath } from "helpers";

const getClassNameValue = (object, data, key) => {
  if (object?.className) {
    if (typeof object?.className === "string")
      return [...object.className.split(" ")];
    if (typeof object?.className === "function")
      return object.className(data[object.dataIndex], data, key);
  } else return data[object.dataIndex];
  return;
};

const getCellValue = (object, data, key) => {
  if (object?.render) {
    if (typeof object?.render === "string") return object.render;
    if (typeof object?.render === "function")
      return object.render(getValueByPath(data, object.dataIndex), data, key);
  } else return getValueByPath(object.dataIndex);
  return;
};

const TableRow = ({ columns, datas, onRowCheck, isCheckedAll }) => {

  if (!columns.length) return null;
  if (!datas.length) {
    return (
      <tr className="text-gray-600 text-sm text-center font-semibold">
        <td colSpan={columns.length} className="text-gray-600 text-sm">
          No data to display
        </td>
      </tr>
    );
  }
  return datas.map((data, key) => {
    return (
      <tr key={key}>
        {columns.map((col, idx) => {
          return (
            <td
              key={idx}
              className={classNames(
                ["text-gray-600", "text-sm", "p-3"],
                { sticky: Boolean(col?.fixed) },
                { "right-0": col?.fixed && col?.fixed === "right" },
                { "left-0": col?.fixed && col?.fixed === "left" },
                `${key % 2 === 0 ? "bg-white" : "bg-gray-50"}`,
                `${key === datas.length - 1 ? "border-b-[1px]" : ""}`,
                getClassNameValue(col, data, key),
              )}
            >
              {col.hasCheckbox ? (
                <div className="flex items-center justify-between">
                  <div className="flex-1">{getCellValue(col, data, key)}</div>
                  <div className="flex items-center ml-2">
                    <input
                      type="checkbox"
                      id={`custom-checkbox-row${key}`}
                      className="hidden peer-row-checkbox"
                      checked={data.checked || isCheckedAll}
                      onChange={() => onRowCheck(key)}
                    />
                    <label
                      for={`custom-checkbox-row${key}`}
                      className="border-[#D0D5DD] border-[1px] relative w-4 h-4 bg-white rounded cursor-pointer flex items-center justify-center peer-row-checkbox-checked:bg-primary-50 peer-row-checkbox-checked:border-primary-600 peer-row-checkbox-checked:border-[1px]"
                    >
                      {data.checked ? (
                        <svg
                          className="w-4 h-4  peer-row-checkbox-checked:block"
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 1L4 6.5L1.5 4"
                            stroke="#00BD52"
                            stroke-width="1.6666"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : null}
                    </label>
                  </div>
                </div>
              ) : (
                getCellValue(col, data, key)
              )}
            </td>
          );
        })}
      </tr>
    );
  });
};

export default React.memo(TableRow);
