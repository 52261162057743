import { Label } from "components";
import { InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useMutation } from "react-query";
import { FormProvider, useForm } from "react-hook-form";

const useBulkApproval = (fn, refetchFn) => {
  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [type, setType] = useState(0);
  const [id, setId] = useState([]);

  const methods = useForm();

  const mutationUpdateStatus = useMutation((payload) => {
    return fn(payload);
  });

  const onSubmitBulk = () => {
    const params = {
      payload: {
        status: type,
        id,
        ...(type === 2 && { reason: methods.watch("reason-decline") }),
      },
    };

    mutationUpdateStatus.mutate(params, {
      onSuccess: () => {
        setApproveModal(false);
        setDeclineModal(false);
        setType(0);

        enqueueSnackbar({
          message: `Update status berhasil`,
          variant: "success",
        });
        refetchFn();
      },
    });
  };

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={approveModal}
          onClose={(data) => {
            setApproveModal(data);
          }}
          onSubmit={onSubmitBulk}
          width="w-1/3"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Setujui Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>
            {/* <CheckboxForm label="Setujui Bersyarat" name="approve" /> */}
          </div>
        </FormModal>
        <FormModal
          open={declineModal}
          onClose={(data) => {
            setDeclineModal(data);
          }}
          onSubmit={onSubmitBulk}
          width="w-1/3"
          btnLabelSubmit="Tolak"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Tolak Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>

            {/* <div className="flex flex-col gap-1">
                  <Label>Kategori Penolakan</Label>
                  <CustomSelectForm className="w-full" name="cateogry-decline" />
                </div> */}

            <div className="flex flex-col gap-1">
              <Label>Alasan Ditolak</Label>
              <InputForm
                type="textarea"
                className="w-full"
                name="reason-decline"
                placeholder="Masukkan alasan ditolak"
              />
            </div>
          </div>
        </FormModal>
      </>
    );
  };

  return {
    methods,
    renderModal,
    approveModal,
    setApproveModal,
    declineModal,
    setDeclineModal,
    type,
    setType,
    id,
    setId,
  };
};

export default useBulkApproval;
