import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import {
  Breadcrumbs,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useQuery } from "react-query";
import { PROGRAM } from "services";
import moment from "moment";
import useGetListTable from "hooks/useGetListTable";

const ListProgram = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(null);

  const {
    isLoading,
    data: programData,
    setOrderBy,
    setOrderDirection,
    setKeyword,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useGetListTable("program-list", PROGRAM.getProgramList);

  // const {
  //   isLoading,
  //   data: programData,
  //   refetch,
  // } = useQuery({
  //   queryKey: [
  //     "program-list",
  //     {
  //       page,
  //       limit,
  //       search: keyword
  //     },
  //   ],
  //   queryFn: async ({ signal, queryKey }) => {
  //     const params = queryKey[1];
  //     let response = await PROGRAM.getProgramList({ ...params }, signal);
  //     return response.data;
  //   },
  // });

  const links = [
    {
      label: "List Program",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(programData?.paginator?.page) *
            parseInt(programData?.paginator?.limit) -
          parseInt(programData?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "program_name",
      title: "Nama Program",
      dataIndex: "program_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "location",
      title: "Lokasi",
      dataIndex: "location",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "duration_start",
      title: "Tanggal Mulai",
      dataIndex: "duration_start",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "duration_end",
      title: "Tanggal Selesai",
      dataIndex: "duration_end",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => <div className="text-center">Action</div>,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/program-list/detail`, { state: data })}
          />
          {/* <BiTrash
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() =>
            //   navigate(`/group/detail`)
            // }
          />
          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/group/form`)}
          /> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={links} />
      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className="flex-1">List Program</TitleText>
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
          <div className="flex-1">
            <CustomSelect value={null} placeholder="Filter" optionsData={[]} />
          </div>
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={programData?.result}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={programData?.paginator}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListProgram;
