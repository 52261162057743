import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoadingUser: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.isLoadingUser = false;
      state.user = action.payload;
    },
    loadingUser: (state) => {
      state.isLoadingUser = true;
    },
    logoutUser: (state) => {
      state.isLoadingUser = false;
      state.user = null;
    },
  },
});

export const { storeUser, loadingUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
