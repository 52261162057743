import React, { useRef, useState } from "react";
import { Breadcrumbs, TitleText } from "components";
import { useQuery } from "react-query";
import { NOTIFICATION } from "services";
import NotificationTable from "./notifikasiTable";

const Notification = () => {
  const links = [
    {
      label: "Notifikasi",
    },
  ];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(null);

  const {
    isLoading,
    data: notificationLists,
    refetch,
  } = useQuery({
    queryKey: ["assignment", { limit, page }],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await NOTIFICATION.getNotificationList(
        { ...params },
        signal
      );
      return response.data;
    },
  });

  const onChangePage = (page) => {
    setPage(page);
    refetch();
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
    refetch();
  };
  console.log({ notificationLists });

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="flex items-center my-3 justify-between">
        <TitleText className="flex-1">Notifikasi</TitleText>
      </div>
      <NotificationTable
        dataSource={notificationLists?.result}
        pagination={notificationLists?.paginator}
        onChangePage={onChangePage}
        isLoading={isLoading}
        onChangeRowsPerPage={onChangeRowsPerPage}
        initialRowPerPage={limit}
        initialPage={page}
      />
    </div>
  );
};

export default Notification;
