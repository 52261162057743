import React from "react";

const AbilityPay = () => {
  return (
    <div className="space-y-4">
      <div className="font-[400] text-sm">
        {
          "Nilai Akhir Indikator: Baik (pendapatan >= dari kewajiban angsuran/pengembalian), Kurang Baik (pendapatan cukup untuk angsuran/pengembalian), Tidak Baik (pendapatan kurang dari angsuran/pengembalian)"
        }
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">
            Sumber pendapatan yg dimiliki :
          </div>
          <div className="text-sm font-[400]">Rp. XXX,XXX</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Angsuran/pengembalian :</div>
          <div className="text-sm font-[400]">Rp. XXX,XXX</div>
        </div>
      </div>
    </div>
  );
};

export default AbilityPay;
