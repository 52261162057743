import DahsboardFDB from "pages/FDB/Dashboard";

const dashboardRoutes = [
  {
    routeType: "public",
    path: "/dashboard-fdb",
    element: DahsboardFDB,
    exact: true,
    type: "canView",
    key: "dashboard-fdb",
  },
];

export default dashboardRoutes;
