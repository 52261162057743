import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";

const Drawer = ({title = "", open, onClose, children}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
      <div onClick={() => onClose(false)} className="flex items-center justify-end fixed inset-0 w-full h-max bg-black/70 z-20">
        <motion.div
          key="modal-confirmation"
          className="bg-white rounded-l-md w-1/2 h-screen"
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <div className="flex flex-col py-4 space-y-4">
            <div className="flex-1 flex items-center justify-between px-8">
              <div className="flex flex-col gap-1">
                <p className="text-sm font-bold">{title}</p>
                <p className="font-normal text-2xl">Pembuatan Tanaman DLL</p>
              </div>
              <CgClose
                className="text-xl cursor-pointer"
                onClick={() => onClose(false)}
              />
            </div>
            <div className="flex-1 border-t max-h-[600px] overflow-y-scroll px-8 py-4">{children}</div>
          </div>
        </motion.div>
      </div>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
