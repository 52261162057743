import { Button, Table, TitleText } from "components";
import {
  useEvaluatePlanningQuery,
  useEvaluationPlanningAdd,
} from "hooks/useEvaluationPlan";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { AiOutlinePlus, AiOutlinePlusCircle } from "react-icons/ai";
import { useQueryClient } from "react-query";

const UnregisteredPlanningTable = ({ date }) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [dataChecked, setDataChecked] = useState([]);

  const { data, isLoading, refetch } = useEvaluatePlanningQuery([
    "evaluate-planning-unregistered",
    {
      page,
      limit,
    },
  ]);

  const paginator = data?.data?.result?.meta;

  const add = useEvaluationPlanningAdd();

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(paginator?.current_page) * parseInt(paginator?.per_page) -
          parseInt(paginator?.per_page) +
          index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nap",
      title: "NAP",
      dataIndex: "request",
      width: 540,
      className: "w-1/2 overflow-hidden w text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value.nap ?? "-"}
        </div>
      ),
    },
    {
      id: "deptor_name",
      title: "Nama Debitur",
      dataIndex: "group_profile_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value.name ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      className: "overflow-hidden w-fit text-center ",
      sortable: false,
      columnClassName: "text-center w-fit",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <AiOutlinePlusCircle
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => handleOnlyOne(data)}
          />
        </div>
      ),
    },
    {
      id: "actions",
      // title: () => <input type="checkbox" />,
      dataIndex: "actions",
      // width: 160,
      className: "overflow-hidden text-ellipsis",
      // columnClassName: "text-center",
      fixed: "right",
      sortable: false,
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <input
            type="checkbox"
            className="cursor-pointer"
            checked={dataChecked.includes(data.id)}
            onChange={() => handleCheck(data)}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const handleCheck = (data) => {
    const id = data.id; // Get the id directly

    setDataChecked((prevState) => {
      if (prevState.includes(id)) {
        // Check if the id is already in the array
        return prevState.filter((x) => x !== id); // Remove the id
      } else {
        return [...prevState, id]; // Add the id
      }
    });
  };

  const handleSelected = () => {
    const payload = {
      debtor_id: dataChecked,
      plan_date: date,
    };

    add.mutate(payload, {
      onSuccess: () => {
        enqueueSnackbar({
          message: `Berhasil!`,
          variant: "success",
        });
        refetch();
        setDataChecked([]);
        queryClient.invalidateQueries({
          queryKey: "evaluate-planning-registered",
        });
        queryClient.invalidateQueries({
          queryKey: "list-timeline",
        });
      },
      onError: (error) => {
        enqueueSnackbar({
          message: error,
          variant: "error",
        });
      },
    });
  };

  const handleOnlyOne = (data) => {
    const debtorId = [];

    debtorId.push(data.id);
    const payload = {
      debtor_id: debtorId,
      plan_date: date,
    };

    if (date) {
      add.mutate(payload, {
        onSuccess: () => {
          enqueueSnackbar({
            message: `Berhasil!`,
            variant: "success",
          });
          refetch();
          setDataChecked([]);
          queryClient.invalidateQueries({
            queryKey: "evaluate-planning-registered",
          });
          queryClient.invalidateQueries({
            queryKey: "list-timeline",
          });
        },
        onError: (error) => {
          enqueueSnackbar({
            message: error,
            variant: "error",
          });
        },
      });
    } else {
      enqueueSnackbar({
        message: "Harap pilih target tanggal terlebih dahulu",
        variant: "error",
      });
    }
  };

  return (
    <div className="flex flex-1 card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
      <div className="flex justify-between card-header p-3 gap-2">
        <TitleText className="flex-1">
          Daftar Debitur yang Belum Terdaftar Perencanaan
        </TitleText>
        <Button
          className="text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
          startIcon={<AiOutlinePlus />}
          disabled={dataChecked.length <= 0 || !date}
          onClick={handleSelected}
        >
          Tambahkan terpilih
        </Button>
      </div>
      <div className="card-body p-3">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumns}
          dataSource={data?.data?.result?.data}
          isLoading={isLoading}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          pagination={{
            page: paginator?.current_page,
            limit: paginator?.per_page,
            itemCount: paginator?.total,
            previous_pages: paginator?.previous_page_url,
            next_pages: paginator?.next_page_url,
          }}
        />
      </div>
    </div>
  );
};

export default UnregisteredPlanningTable;
