import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumbs, CollapsingCard, Table } from "components";
import { map } from "lodash";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";

import GroupInformation from "./groupInformation";
import BusinessData from "./businessData";
import ApplicationData from "./applicationData";

import MonitoringPlan from "./MonitoringPlan";
import PerformanceEvaluation from "./PerformanceEvaluation";
import ActivityDocument from "./ActivityDocument";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { GROUP } from "services";

const GroupDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(0);

  const [memberPage, setMemberPage] = useState(1);
  const [memberLimit, setMemberLimit] = useState(10);

  const id = useMemo(() => location?.state?.group_id ?? null);

  const tabs = [
    "Rencana Monitoring",
    "Evaluasi Kinerja",
    "Dokumentasi Kegiatan",
    "Sumber Dana",
  ];

  const { isLoading, data: groupDetail } = useQuery({
    queryKey: ["group-detail", id],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await GROUP.getGroupDetail(params);
      return response.result;
    },
    enabled: !!id, // This line disables the query when id is null or undefined
  });


  const groupMember = [];
  // const {
  //   isLoading: isLoadingMember,
  //   data: groupMember,
  //   refetch,
  // } = useQuery({
  //   queryKey: [
  //     "group-member",
  //     { id, payload: { page: memberPage, size: memberLimit } },
  //   ],
  //   queryFn: async ({ signal, queryKey }) => {
  //     const params = queryKey[1];
  //     let response = await GROUP.getGroupMember({ ...params }, signal);
  //     return response.result;
  //   }
  // });

  const links = [
    {
      path: "/deptor",
      label: "Daftar Debitur",
    },
    {
      label: "Detail Debitur",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 50,
      previous_pages: true,
      next_pages: true,
    };
  });

  const onChangePage = (page) => {
    setMemberPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setMemberLimit(limit);
  };

  const tableColumnsGroupMember = [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "nap",
        title: "NAP",
        dataIndex: "nap",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "receiver_name",
        title: "Nama Penerima",
        dataIndex: "receiver_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "korwil",
        title: "Korwil",
        dataIndex: "korwil",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "status",
        title: "Status",
        dataIndex: "status",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "submit_date",
        title: "Tgl Submit",
        dataIndex: "submit_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Action",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/deptor/detail`)}
            />
            <BiTrash
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              // onClick={() =>
              //   navigate(`/deptor/detail`)
              // }
            />
            <BiPencil
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => navigate(`/deptor/form`)}
            />
          </div>
        ),
      },
    ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      {groupDetail?.member_application?.group_profile && (
        <CollapsingCard title="Informasi Kelompok">
          <GroupInformation
            kupsName={groupDetail?.member_application?.group_profile?.kups_name}
            schemePs={"-"}
            appCategory={"-"}
            sk={groupDetail?.member_application?.group_profile?.sk}
            permissionNumberPS={"="}
            amountOfMember={
              groupDetail?.member_application?.group_profile?.amount_of_member
            }
            address={groupDetail?.member_application?.group_profile?.address}
            long={groupDetail?.member_application?.group_profile?.long}
            lat={groupDetail?.member_application?.group_profile?.lat}
            leaderName={
              groupDetail?.member_application?.group_profile?.leader_name
            }
            leaderPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.leader_phone_number
            }
            secretaryName={
              groupDetail?.member_application?.group_profile?.secretary_name
            }
            secretaryPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.secretary_phone_number
            }
            treasurerName={
              groupDetail?.member_application?.group_profile?.treasurer_name
            }
            treasurerPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.treasurer_phone_number
            }
            companionName={
              groupDetail?.member_application?.group_profile?.companion_name
            }
            companionPhoneNumber={
              groupDetail?.member_application?.group_profile
                ?.companion_phone_number
            }
            companionStatus={
              groupDetail?.member_application?.group_profile?.companion_status
            }
            businessPartner={"-"}
            activity={"-"}
            general_description={
              groupDetail?.member_application?.group_profile?.status
            }
          />
        </CollapsingCard>
      )}
      <CollapsingCard title="Data Usaha">
        <BusinessData
          name={groupDetail?.business_type ? groupDetail?.business_type : "-"}
          duration={
            groupDetail?.business_duration &&
            groupDetail?.business_duration_unit
              ? `${groupDetail?.business_duration} ${groupDetail?.business_duration_unit}`
              : "-"
          }
          priceLastSeller={
            groupDetail?.business_economic_value
              ? groupDetail?.business_economic_value
              : "-"
          }
          marketingObjective={
            groupDetail?.marketing_objective
              ? groupDetail?.marketing_objective
              : "-"
          }
          quantityCommodity={
            groupDetail?.qty_business_commodity
              ? groupDetail?.qty_business_commodity
              : "-"
          }
          usagePlan={groupDetail?.usage_plan ? groupDetail?.usage_plan : "-"}
          businessCommodity={
            groupDetail?.business_commodity
              ? groupDetail?.business_commodity
              : "-"
          }
          productivity={
            groupDetail?.productivity ? groupDetail?.productivity : "-"
          }
          cultivated={
            groupDetail?.land_area_cultivated
              ? groupDetail?.land_area_cultivated
              : "-"
          }
          businessPartner={
            groupDetail?.business_partner ? groupDetail?.business_partner : "-"
          }
          address={
            groupDetail?.business_address ? groupDetail?.business_address : "-"
          }
          long={groupDetail?.business_lat ? groupDetail?.business_lat : "-"}
          lat={groupDetail?.business_long ? groupDetail?.business_long : "-"}
        />
      </CollapsingCard>
      <CollapsingCard title="Data Permohonan">
        <ApplicationData
          amount_of_loan={
            groupDetail?.amount_of_loan ? groupDetail?.amount_of_loan : "-"
          }
          period={
            groupDetail?.time_period && groupDetail?.time_period_unit
              ? `${groupDetail?.time_period} ${groupDetail?.time_period_unit}`
              : "-"
          }
          financing_scheme={
            groupDetail?.financing_scheme ? groupDetail?.financing_scheme : "-"
          }
        />
      </CollapsingCard>
      <CollapsingCard title="Daftar Anggota Kelompok">
        <Table
          bordered
          stripped
          layout="fixed"
          className="mb-4"
          columns={tableColumnsGroupMember}
          // dataSource={groupMember?.data}
          dataSource={[]}
          isLoading={false}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          // pagination={groupMember?.paginator}
          pagination={[]}
        />
      </CollapsingCard>
      <div className="space-y-4">
        <div className="tabs">
          {map(tabs, (tab, key) => (
            <button
              onClick={() => setActive(key)}
              className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                key === active
                  ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                  : "border-b"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
        {active === 0 && <MonitoringPlan />}
        {active === 1 && <PerformanceEvaluation />}
        {active === 2 && <ActivityDocument />}
      </div>
    </div>
  );
};

export default GroupDetail;
