import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumbs, CollapsingCard, Table } from "components";
import { map } from "lodash";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";


import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import { PROGRAM } from "services";
import ProgramInformation from "./programInformation";
import GeneralInformation from "./general_information";
import Location from "./location";
import Beneficiaries from "./beneficiaries";

const ProgramDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(0);

  const [memberPage, setMemberPage] = useState(1);
  const [memberLimit, setMemberLimit] = useState(10);

  const id = useMemo(() => location?.state?.program_id ?? null);

  const tabs = [
    "Rencana Monitoring",
    "Evaluasi Kinerja",
    "Dokumentasi Kegiatan",
    "Sumber Dana",
  ];

  const { isLoading, data: programDetail } = useQuery({
    queryKey: ["program-detail", id],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await PROGRAM.getProgramDetail(params);
      return response.result;
    },
  });

  const links = [
    {
      path: "/program-list",
      label: "List Program",
    },
    {
      label: "Detail Program",
    },
  ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      {programDetail?.program && (
        <CollapsingCard title="Informasi Program">
          <ProgramInformation
            data={programDetail?.program}
          />
        </CollapsingCard>
      )}
      <CollapsingCard title="Data Lokasi">
        <Location
          data={programDetail?.location}
        />
      </CollapsingCard>
      <CollapsingCard title="Informasi Umum">
      <GeneralInformation
          data={programDetail?.general_information}
        />
      </CollapsingCard>
      <CollapsingCard title="Data Penerima Manfaat">
        <Beneficiaries
          data={programDetail?.beneficiaries}
        />
      </CollapsingCard>

    </div>
  );
};

export default ProgramDetail;
