import { RadioButtonForm } from "components";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

const RadioGroupForm = ({ name, items }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className="flex space-x-6">
            {items.map((item, key) => {
              return (
                <label className="flex space-x-2 items-center" key={key}>
                  <input
                    name={name}
                    value={value.toString() === item.value.toString()}
                    checked={value.toString() === item.value.toString()}
                    className="radio radio-success radio-xs"
                    type="radio"
                    onChange={(val) => {
                      onChange(item.value);
                    }}
                    defaultChecked={value.toString() === item.value.toString()}
                  />
                  <span className="text-sm text-gray-700">{item.label}</span>
                </label>
              );
            })}
            {invalid && (
              <div className="text-xs text-error-600 mt-2">
                {error?.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default RadioGroupForm;
