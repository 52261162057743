import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumbs, CollapsingCard } from "components";
import { map } from "lodash";

import DeptorProfile from "./deptorProfile";
import GroupInformation from "./groupInformation";
import BusinessData from "./businessData";
import ApplicationData from "./applicationData";

import MonitoringPlan from "./MonitoringPlan";
import PerformanceEvaluation from "./PerformanceEvaluation";
import ActivityDocument from "./ActivityDocument";
import Monev from "./Monev";
import SourceOfFunds from "./SourceOfFunds";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { DEPTOR } from "services";
import { formatCurrency } from "helpers";

const DeptorDetail = () => {
  const { state } = useLocation();
  const [active, setActive] = useState(0);

  const tabs = ["Rencana Monitoring", "Monev", "Sumber Dana"];
  
  const { isLoading, data: deptorDetail } = useQuery({
    queryKey: ["deptor-detail", state.id],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await DEPTOR.getDeptorDetail(params);
      return response.data;
    },
  });


  const links = [
    {
      path: "/deptor",
      label: "Daftar Debitur",
    },
    {
      label: "Detail Debitur",
    },
  ];

  useEffect(() => {
    return;
  }, []);

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <CollapsingCard title="Profil Debitur">
        <DeptorProfile
          name={deptorDetail?.debtor?.name ?? "-"}
          nik={deptorDetail?.debtor?.ktp ?? "-"}
          nap={deptorDetail?.debtor?.nap ?? "-"}
          deptorGroup={deptorDetail?.debtor?.group_name ?? "-"}
          address={deptorDetail?.debtor?.domicile_address ?? "-"}
          long={deptorDetail?.debtor?.domicile_longitude ?? "-"}
          lat={deptorDetail?.debtor?.domicile_latitude ?? "-"}
          pic={deptorDetail?.debtor?.pic ?? "-"}
          korwil={deptorDetail?.debtor?.korwil ?? "-"}
          bankName={deptorDetail?.debtor?.bank ?? "-"}
          job={deptorDetail?.debtor?.job ?? "-"}
          accountNumber={deptorDetail?.debtor?.bank_account ?? "-"}
          expense={formatCurrency(deptorDetail?.debtor?.expense ?? 0)}
          expenseSource={deptorDetail?.debtor?.largest_use_expense ?? "-"}
          income={formatCurrency(deptorDetail?.debtor?.income ?? 0)}
        />
      </CollapsingCard>
      {deptorDetail?.group_info && (
        <CollapsingCard title="Informasi Kelompok">
          <GroupInformation
            kupsName={deptorDetail?.group_info?.kups_name ?? "-"}
            schemePs={deptorDetail?.group_info?.ps_scheme ?? "-"}
            appCategory={deptorDetail?.group_info?.application_category ?? "-"}
            sk={deptorDetail?.group_info?.sk_number ?? "-"}
            permissionNumberPS={"-"}
            amountOfMember={deptorDetail?.group_info?.total_member ?? "-"}
            address={deptorDetail?.group_info?.address ?? "-"}
            long={deptorDetail?.group_info?.long ?? "-"}
            lat={deptorDetail?.group_info?.lat ?? "-"}
            leaderName={deptorDetail?.group_info?.leader_name ?? "-"}
            leaderPhoneNumber={deptorDetail?.group_info?.leader_phone ?? "-"}
            secretaryName={deptorDetail?.group_info?.secretary_name ?? "-"}
            secretaryPhoneNumber={
              deptorDetail?.group_info?.secretary_phone ?? "-"
            }
            treasurerName={deptorDetail?.group_info?.treasurer_name ?? "-"}
            treasurerPhoneNumber={
              deptorDetail?.group_info?.treasurer_phone ?? "-"
            }
            companionName={deptorDetail?.group_info?.companion_name ?? "-"}
            companionPhoneNumber={
              deptorDetail?.group_info?.companion_phone ?? "-"
            }
            companionStatus={deptorDetail?.group_info?.treasurer_status ?? "-"}
            businessPartner={"-"}
            activity={deptorDetail?.group_info?.activity ?? "-"}
            general_description={deptorDetail?.group_info?.status ?? "-"}
          />
        </CollapsingCard>
      )}
      <CollapsingCard title="Data Usaha">
        <BusinessData
          name={deptorDetail?.data_business?.business_type ?? "-"}
          duration={deptorDetail?.data_business?.business_duration ?? "-"}
          priceLastSeller={formatCurrency(
            deptorDetail?.data_business?.recent_sale ?? 0
          )}
          marketingObjective={
            deptorDetail?.data_business?.marketing_objective ?? "-"
          }
          quantityCommodity={
            deptorDetail?.data_business?.qty_business_commodity ?? "-"
          }
          usagePlan={deptorDetail?.data_business?.usage_plan ?? "-"}
          businessCommodity={
            deptorDetail?.data_business?.business_commodity ?? "-"
          }
          productivity={deptorDetail?.data_business?.productivity ?? "-"}
          cultivated={deptorDetail?.data_business?.land_area_cultivated ?? "-"}
          businessPartner={deptorDetail?.data_business?.partner_business ?? "-"}
          address={deptorDetail?.data_business?.address ?? "-"}
          long={deptorDetail?.data_business?.longitude ?? "-"}
          lat={deptorDetail?.data_business?.latitude ?? "-"}
        />
      </CollapsingCard>
      <CollapsingCard title="Data Permohonan">
        <ApplicationData
          amount_of_loan={formatCurrency(
            deptorDetail?.application_data?.loan_amount_approved ?? 0
          )}
          amount_of_loan_spelling={
            deptorDetail?.application_data?.spelled ?? "-"
          }
          period={deptorDetail?.application_data?.time_period}
          financing_scheme={
            deptorDetail?.application_data?.scheme_finance ?? "-"
          }
          total_member={
            deptorDetail?.application_data?.total_member_application ?? "-"
          }
        />
      </CollapsingCard>
      <div className="space-y-4">
        <div className="tabs">
          {map(tabs, (tab, key) => (
            <a
              onClick={() => setActive(key)}
              className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
                key === active
                  ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                  : "border-b"
              }`}
            >
              {tab}
            </a>
          ))}
        </div>
        {active === 0 && <MonitoringPlan />}
        {/* {active === 2 && <PerformanceEvaluation />} */}
        {active === 1 && <Monev />}
        {active === 2 && <SourceOfFunds />}
      </div>
    </div>
  );
};

export default DeptorDetail;
