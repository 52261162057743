import React from "react";

const BusinessData = ({
  name = "-",
  duration = "-",
  priceLastSeller = "-",
  marketingObjective = "-",
  quantityCommodity = "-",
  usagePlan = "-",
  businessCommodity = "-",
  productivity = "-",
  cultivated = "-",
  businessPartner = "-",
  address = "-",
  long = "-",
  lat = "-",
}) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Nama Usaha :</div>
            <div className="text-sm font-[400]">{name}</div>
            <div className="text-sm font-[700]">Lama Usaha :</div>
            <div className="text-sm font-[400]">{duration}</div>
            <div className="text-sm font-[700]">
              Harga (Penjualan Terakhir) :
            </div>
            <div className="text-sm font-[400]">{priceLastSeller}</div>
            <div className="text-sm font-[700]">Tujuan Pemasaran :</div>
            <div className="text-sm font-[400]">{marketingObjective}</div>
            <div className="text-sm font-[700]">Kuantitas Komoditas :</div>
            <div className="text-sm font-[400]">{quantityCommodity}</div>
            <div className="text-sm font-[700]">
              Rencana Penggunaan Dana Pinjaman :
            </div>
            <div className="text-sm font-[400]">{usagePlan}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Komoditas Usaha :</div>
            <div className="text-sm font-[400]">{businessCommodity}</div>
            <div className="text-sm font-[700]">
              Produktivitas/Hasil Panen Terakhir :
            </div>
            <div className="text-sm font-[400]">{productivity}</div>
            <div className="text-sm font-[700]">
              Luasan Lahan yg digarap (m2) :
            </div>
            <div className="text-sm font-[400]">{cultivated}</div>
            <div className="text-sm font-[700]">Mitra Usaha :</div>
            <div className="text-sm font-[400]">{businessPartner}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Alamat :</div>
            <div className="text-sm font-[400]">{address}</div>
            <div className="text-sm font-[700]">Longitude :</div>
            <div className="text-sm font-[400]">{long}</div>
            <div className="text-sm font-[700]">Latitude :</div>
            <div className="text-sm font-[400]">{lat}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessData;
