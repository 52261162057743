import assignmentRoutes from "./assignment";
import dashboardRoutes from "./dashboard";
import deptorRoutes from "./deptor";
import groupRoutes from "./group";
import indicatorRoutes from "./indicator";
import monitoringRoutes from "./monitoring";
import paperWorkRoutes from "./paper-work";
import planningRoutes from "./planning";

const fdbRoutes = [
  ...assignmentRoutes,
  ...dashboardRoutes,
  ...deptorRoutes,
  ...groupRoutes,
  ...indicatorRoutes,
  ...monitoringRoutes,
  ...paperWorkRoutes,
  ...planningRoutes,
];

export default fdbRoutes;
