import { INVESTIGATE } from "services";
import { useQuery, useMutation } from "react-query";

export const useInvestigateDetail = (params) => {
  return useQuery({
    queryKey: [`investigate-detail`, params.id],
    queryFn: async () => {
      const resp = await INVESTIGATE.getInvestigateDetail(params.id);
      return resp.data;
    },
  });
};

export const useInvestigateResult = () => {
  return useMutation((payload) => INVESTIGATE.putInvestigateResult(payload));
};
