import config from "app/config";
import axiosInstance from "app/interceptors";

export const getIndicatorList = (params, signal) => {
  return axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/indicator`, { params, signal });
}

export const createNewIndicator = (payload) => {
  return axiosInstance.post(`${config.BASE_URL}/api/monitoring-evaluasi/v1/indicator`, payload);
}

export const editIndicator = (params) => {
  return axiosInstance.put(`${config.BASE_URL}/api/monitoring-evaluasi/v1/indicator/${params?.id}`, params?.payload);
}

export const deleteIndicator = (id) => {
  return axiosInstance.delete(`${config.BASE_URL}/api/monitoring-evaluasi/v1/indicator/${id}`);
}