import ListProgram from "pages/Program/ListProgram";
import ProgramDetail from "pages/Program/ListProgram/Detail";

const listRoutes = [
  {
    routeType: "public",
    path: "/program-list",
    element: ListProgram,
    exact: true,
    type: "canView",
    key: "program-list",
  },
  {
    routeType: "public",
    path: "/program-list/detail",
    element: ProgramDetail,
    exact: true,
    type: "canView",
    key: "program-list-detail",
  },
];

export default listRoutes;
