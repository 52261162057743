import moment from "moment";

export const API = async ({ params, service }, callback) => {
  try {
    const response = await service(params);
    if (response?.data?.meta?.success) {
      callback?.(true, response?.data);
    } else {
      callback?.(false, response?.data);
    }
    return response;
  } catch (error) {
    callback?.(false, error?.response);
    throw error;
  }
};

export const getErrorMessage = (error) => {
  let message =
    error?.response?.data?.data?.message ??
    error?.response?.data?.meta?.message ??
    error?.response?.data?.message ??
    error?.response?.message ??
    error?.message ??
    "Something Went Wrong";
  return message;
};

export const formatCurrency = (data) => {
  return Number(data).toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const getDates = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  let enddate = moment(stopDate);
  while (currentDate <= enddate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "years");
  }
  return dateArray;
};

export function getValueByPath(obj, path) {
  const keys = path.split(".");
  let result = obj;

  for (let key of keys) {
    result = result[key];
    if (result === undefined) {
      return undefined;
    }
  }

  return result;
}

export const convertBytes = (bytes) => {
  const KB = 1024;
  const MB = KB * 1024;

  let convertedValue;
  let unit;

  if (bytes >= MB) {
    convertedValue = bytes / MB;
    unit = "MB";
  } else {
    convertedValue = bytes / KB;
    unit = "KB";
  }

  return `${convertedValue.toFixed(2)} ${unit}`;
};
