import Group from "pages/Group";
import GroupDetail from "pages/Group/Detail";

const groupRoutes = [
  {
    routeType: "public",
    path: "/group",
    element: Group,
    exact: true,
    type: "canView",
    key: "group",
  },
  {
    routeType: "public",
    path: "/group/detail",
    element: GroupDetail,
    exact: true,
    type: "canView",
    key: "group-detail",
  },
];

export default groupRoutes;
