import { useMutation, useQuery } from "react-query";
import { ASSIGNMENT } from "services";

export const useAssignmentFDBQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await ASSIGNMENT.getAssignment({ ...params }, signal);
      return response.data;
    },
    ...options,
  });
};

export const useAssignmentFDBDetail = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({ queryKey }) => {
      const params = queryKey[1];
      let response = await ASSIGNMENT.getAssignmentDetail(params);
      return response.result;
    },
    ...options,
  });
};

export const useAssignmentFDBByUser = (id, keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: async ({signal, queryKey}) => {
      const params = queryKey[1];
        let response = await ASSIGNMENT.getAssignmentByUser(
          id,
          { ...params },
          signal
        );
        return response.data?.result;
    }
  })
}

export const useAssignmentPatch = () => {
  return useMutation((payload) => ASSIGNMENT.putAssignmentByUser(payload));
};
