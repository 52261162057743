import config from "app/config";
import axiosInstance from "app/interceptors";

export const uploadFile = async (payload) => {
  const { data } = await axiosInstance.post(
    `${config.BASE_URL_BPLDH}/file-service/v1/files`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};
