import { useQuery } from "react-query";
import { ACTIVITY_REPORT } from "services";

export const useKegiatanProgressDetail = (id) => {
  return useQuery({
    queryKey: [`kegiatan-detail`, id],
    queryFn: async () => {
      const resp = await ACTIVITY_REPORT.getLaporanAktifitasDetail(id);
      return resp.data;
    },
  });
};
