import config from "app/config";
import axiosInstance from "app/interceptors";

export const getInvestigate = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/investigation-report`,
    { params, signal }
  );
};

export const getInvestigateDetail = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/investigation-report/${id}`
  );
};

export const putInvestigateResult = (payload) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/investigation-report/${payload.id}/result`,
    payload
  );
};
