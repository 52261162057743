import Deptor from "pages/Deptor";
import DeptorDetail from "pages/Deptor/Detail";

const deptorRoutes = [
  {
    routeType: "public",
    path: "/deptor",
    element: Deptor,
    exact: true,
    type: "canView",
    key: "deptor",
  },
  {
    routeType: "public",
    path: "/deptor/detail",
    element: DeptorDetail,
    exact: true,
    type: "canView",
    key: "deptor-detail",
  },
]

export default deptorRoutes;