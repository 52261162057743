import React from "react";

const BusinessProspect = () => {
  return (
    <div className="space-y-4">
      <div className="font-[400] text-sm">
        {
          "Nilai Akhir Indikator: Baik (Usaha Berkembang), Kurang Baik (Usaha Stagnan), Tidak Baik (Usaha Menurun)"
        }
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Omset Usaha sebelum menerima FDB :</div>
          <div className="text-sm font-[400]">Rp. XXX,XXX,XXX</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Omset Usaha setelah menerima FDB :</div>
          <div className="text-sm font-[400]">Rp. XXX,XXX,XXX</div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProspect;
