import MainPage from "pages";
import Notification from "pages/Notification";

// grouping routes
import danaProgramRoutes from "./dana-program-routes";
import fdbRoutes from "./fdb-routes";

const evaluate = [
  {
    routeType: "public",
    path: "#",
    element: <></>,
    exact: true,
    key: "evaluate",
  },
];

const notification = [
  {
    routeType: "public",
    path: "/notification",
    element: Notification,
    exact: true,
    type: "canView",
    key: "notification",
  },
];

const routesName = [
  {
    routeType: "public",
    path: "/",
    element: MainPage,
    exact: true,
    type: "canView",
    key: "home",
  },
  ...evaluate,
  ...notification,
  ...danaProgramRoutes,
  ...fdbRoutes,
];

export default routesName;
