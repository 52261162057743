import React from "react";

const GroupInformation = ({
  kupsName = "-",
  schemePs = "-",
  appCategory = "-",
  sk = "-",
  permissionNumberPS = "-",
  amountOfMember = "-",
  address = "-",
  long = "-",
  lat = "-",
  leaderName = "-",
  leaderPhoneNumber = "-",
  secretaryName = "-",
  secretaryPhoneNumber = "-",
  treasurerName = "-",
  treasurerPhoneNumber = "-",
  companionName = "-",
  companionPhoneNumber = "-",
  companionStatus = "-",
  businessPartner = "-",
  activity = "-",
  general_description = "-",
}) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Nama KUPS :</div>
            <div className="text-sm font-[400]">{kupsName}</div>
            <div className="text-sm font-[700]">Skema PS :</div>
            <div className="text-sm font-[400]">{schemePs}</div>
            <div className="text-sm font-[700]">Kategori Permohonan :</div>
            <div className="text-sm font-[400]">{appCategory}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">SK Pembentukkan :</div>
            <div className="text-sm font-[400]">{sk}</div>
            <div className="text-sm font-[700]">No Izin PS :</div>
            <div className="text-sm font-[400]">{permissionNumberPS}</div>
            <div className="text-sm font-[700]">Jumlah anggota :</div>
            <div className="text-sm font-[400]">{amountOfMember}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Alamat :</div>
            <div className="text-sm font-[400]">{address}</div>
            <div className="text-sm font-[700]">Longitude :</div>
            <div className="text-sm font-[400]">{long}</div>
            <div className="text-sm font-[700]">Latitude :</div>
            <div className="text-sm font-[400]">{lat}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Nama Ketua PS :</div>
            <div className="text-sm font-[400]">{leaderName}</div>
            <div className="text-sm font-[700]">Nama Sekretaris :</div>
            <div className="text-sm font-[400]">{secretaryName}</div>
            <div className="text-sm font-[700]">Nama Bendahara :</div>
            <div className="text-sm font-[400]">{treasurerName}</div>
            <div className="text-sm font-[700]">Nama Pendamping :</div>
            <div className="text-sm font-[400]">{companionName}</div>
            <div className="text-sm font-[700]">Status Pendamping :</div>
            <div className="text-sm font-[400]">{companionStatus}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">No. Telepon Ketua :</div>
            <div className="text-sm font-[400]">{leaderPhoneNumber}</div>
            <div className="text-sm font-[700]">No. Telepon Sekretaris :</div>
            <div className="text-sm font-[400]">{secretaryPhoneNumber}</div>
            <div className="text-sm font-[700]">No. Telepon Bendahara :</div>
            <div className="text-sm font-[400]">{treasurerPhoneNumber}</div>
            <div className="text-sm font-[700]">No. Telepon Pendamping :</div>
            <div className="text-sm font-[400]">{companionPhoneNumber}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">&nbsp;</div>
      </div>
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Mitra Usaha :</div>
            <div className="text-sm font-[400]">{businessPartner}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">
              Gambaran umum jenis-jenis usaha anggota kelompok yg dibiayai :
            </div>
            <div className="text-sm font-[400]">{activity}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">&nbsp;</div>
      </div>
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">
              Kegiatan - kegiatan kelompok yang telah dilaksanakan :
            </div>
            <div className="text-sm font-[400]">{general_description}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">&nbsp;</div>
        <div className="flex flex-col flex-1 space-y-4">&nbsp;</div>
      </div>
    </div>
  );
};

export default GroupInformation;
