import { useQuery } from "react-query";
import { PROGRESS_REPORT } from "services";

export const useProgramProgressDetail = (id) => {
  return useQuery({
    queryKey: [`program-detail`, id],
    queryFn: async () => {
      const resp = await PROGRESS_REPORT.getLaporanKemajuanDetail(id);
      return resp.data;
    },
  });
};
