import React from "react";

const TypeBadge = ({ data }) => {
  let defaultDotClass = "bg-gray-500";
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (data.id) {
    case 1:
      defaultDotClass = "bg-primary-500";
      defaultWrapperClass = "bg-primary-50 text-primary-700";
      break;
    case 2:
      defaultDotClass = "bg-[#B93815]";
      defaultWrapperClass = "bg-[#FFF4ED] text-[#B93815]";
      break;
    default:
      defaultDotClass = "bg-gray-500";
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      <div
        className={`rounded-[50%] w-2 h-2 inline-block mr-1 ${defaultDotClass}`}
      />
      {data?.name ?? "-"}
    </div>
  );
};

export default TypeBadge;
