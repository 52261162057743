import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";

import {
  Breadcrumbs,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { useQuery } from "react-query";
import { GROUP } from "services";
import moment from "moment";

const Group = () => {
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState(undefined);
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    isLoading,
    data: groupData,
    refetch,
  } = useQuery({
    queryKey: [
      "group",
      {
        page,
        limit,
        search: keyword
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await GROUP.getGroupList({ ...params }, signal);
      return response.data;
    },
  });

  const links = [
    {
      label: "Daftar Kelompok",
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(groupData?.paginator?.page) * parseInt(groupData?.paginator?.limit) -
          parseInt(groupData?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "Nama Kelompok / Institusi",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "leader_name",
      title: "Nama Ketua",
      dataIndex: "leader_name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "amount_of_member",
      title: "Anggota Debitur",
      dataIndex: "amount_of_member",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "register_date",
      title: "Tgl Daftar",
      dataIndex: "register_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "total",
      title: "Total",
      dataIndex: "total",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => <div className="text-center">Action</div>,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/group/detail`, {state: data})}
          />
          {/* <BiTrash
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            // onClick={() =>
            //   navigate(`/group/detail`)
            // }
          />
          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => navigate(`/group/form`)}
          /> */}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumbs items={links} />
      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className="flex-1">Daftar Kelompok</TitleText>
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            <SearchInput placeholder="Pencarian" onChange={onSearch} />
          </div>
          <div className="flex-1">
            <CustomSelect value={null} placeholder="Filter" optionsData={[]} />
          </div>
        </div>
      </div>
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={groupData?.result}
              // isLoading={isLoading}
              // onChangePage={onChangePage}
              // onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={groupData?.paginator}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
