import { CustomSelect, Label, Spinner } from "components";
import { InputForm, RadioGroupForm } from "components/HookForm";
import useMasterData from "hooks/useMasterData";
import React from "react";
import { useFormContext } from "react-hook-form";

const IndicatorForm = () => {
  const { watch } = useFormContext();

  const listProbis = [
    {
      label: "Ya",
      value: true,
    },
    {
      label: "Tidak",
      value: false,
    },
  ];

  const { data, isLoading } = useMasterData("Directorat");

  const dataDirektorat =
    data?.data?.length > 0
      ? data?.data?.map((list) => {
          return {
            label: list.name,
            value: list.id,
          };
        })
      : [];

  const directorat_id = watch("directorat_id");
  const hit_service = watch("hit_service");

  return (
    <div className="h-full space-y-4">
      <div className="flex flex-col gap-2 ">
        <Label>Nama Indikator</Label>
        <InputForm name={`name`} className="w-full" />
      </div>
      <div className="flex justify-between">
        <span className="text-sm ">
           Apakah mau mengambil data dari probis lain?
        </span>
        <RadioGroupForm items={listProbis} name="hit_service" />
      </div>
      {hit_service ? (
        <div className="flex flex-col gap-2 relative">
          <Label>Pilih Direktorat</Label>
          {isLoading ? (
            <Spinner />
          ) : (
            dataDirektorat.length > 0 && (
              <CustomSelect
                defaultValue={dataDirektorat?.find(
                  (data) => data.value === directorat_id
                )}
                name="directorat_id"
                placeholder="Pilih Direktorat"
                optionsData={dataDirektorat}
                className="w-full"
              />
            )
          )}
        </div>
      ) : null}
    </div>
  );
};

export default IndicatorForm;
