import React, { useState } from "react";
import DocIcon from "../../../assets/doc-green.svg";
import { FormModal } from "components/Modal";

const ActivityDocument = ({ data }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [imageActive, setImageActive] = useState(null);

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={isOpenModal}
          onClose={setOpenModal}
          width="w-1/3"
          hasButton={false}
        >
          <img src={imageActive} class="w-full" alt="current image" />
        </FormModal>
      </>
    );
  };

  return (
    <div className="space-y-8">
      {renderModal()}
      <div className="flex flex-col lg:flex-row space-y-8 lg:space-x-8 lg:space-y-8">
        <div className="flex flex-col w-full gap-2">
          <div className="flex text-sm gap-2">
            <p className="font-bold">Longitude:</p>
            <p className="font-[400]">{data?.longitude}</p>
          </div>
          <div className="flex text-sm gap-2">
            <p className="font-bold">Latitude:</p>
            <p className="font-[400]">{data?.latitude}</p>
          </div>
        </div>
      </div>
      {data?.attachments?.map((value, key) => {
        let pathName = "";
        if (value.path) {
          const pathNameSplit = value?.path?.split(".");
          pathName = value?.path?.split(".")[pathNameSplit.length - 1];
        }
        return (
          <div className="border-gray-200 px-4 py-2 rounded-md border-2">
            <div className="flex items-start gap-4">
              <img src={DocIcon} alt="doc-icon" />
              <div classsName="text-sm font-normal">
                <p className="text-sm font-normal">Dokumen Bukti</p>

                {pathName === "pdf" ? (
                  <a href={value.url} target="#" className="color-[##00BD52]">
                    Lihat Dokumen
                  </a>
                ) : (
                  <button
                    onClick={() => {
                      setImageActive(value.url);
                      setOpenModal(true);
                    }}
                    target="#"
                    className="color-[##00BD52]"
                  >
                    Lihat Gambar
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ActivityDocument;
