import React from "react";

const MonitoringPlan = () => {
  return (
    <div className="flex-1 space-y-4">
      <div className="flex-1 border border-gray-200 rounded-xl overflow-hidden">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Januari</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Februari</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Maret</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">April</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Mei</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Juni</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex-1 border border-gray-200 rounded-xl overflow-hidden">
        <table className="table table-compact w-full">
          <thead>
            <tr>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Juli</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Agustus</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">September</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Oktober</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">November</th>
              <th className="w-1/6 text-center bg-primary-50 text-gray-600">Desember</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonitoringPlan;
