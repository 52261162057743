import config from "app/config";
import axiosInstance from "app/interceptors";

export const getProgramList = (params, signal) => {
  return axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/program`, { params, signal });
}

export const getProgramDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/program/${id}`);
  return data;
}