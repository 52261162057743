import classNames from "classnames";
import React, { Fragment } from "react";

const CardHeader = ({
  children,
  className = "text-[#101828] border-gray-200",
}) => {
  return (
    <Fragment>
      <div
        className={classNames(
          "px-6 py-5 text-lg font-semibold  border-b-[1px]",
          className
        )}
      >
        {children}
      </div>
    </Fragment>
  );
};

export default CardHeader;
