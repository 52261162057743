import config from "app/config";
import axiosInstance from "app/interceptors";

export const getEvaluation = (params, signal) => {
  switch (params.status) {
    case 1:
      params.is_approve = true;
      break;
    case 2:
      params.is_approve = false;
      break;
  }

  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation`,
    { params, signal }
  );
};

export const getEvaluationDetail = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation/${id}`
  );
  return data;
};

export const putUpdateEvaluation = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation/approval/${params.id}`,
    params.payload
  );
};
