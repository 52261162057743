import classNames from "classnames";
import {
  Breadcrumbs,
  CollapsingCard,
  CustomSelect,
  TitleText,
} from "components";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import PieChart from "components/Chart/PieChart";
import { formatCurrency } from "helpers";
import useGetListTable from "hooks/useGetListTable";
import { FaMoneyBills } from "react-icons/fa6";
import { LuClipboardList } from "react-icons/lu";
import { DASHBOARD } from "services";

const Dashboard = () => {
  const links = [
    {
      label: "Dashboard",
    },
  ];

  // useQuery({
  //   queryKey:  "dashboard-indikator",
  //   queryFn: async ({ signal, queryKey }) => {
  //     const params = queryKey[1];
  //     let response = await   DASHBOARD.getDashboard({
  //       id: "indicator",
  //     }, );
  //     return response.data;
  //   },
  // })

  const { data: dataIndicator, isLoading } = useGetListTable(
    "dashboard-indikator",
    DASHBOARD.getDashboard,
    {
      id: "indicator",
    }
  );

  const calculateIndicatorScore = () => {
    if (!dataIndicator) return 0;
    const total = dataIndicator.result.reduce(
      (sum, item) => sum + item.scores,
      0
    );

    // return (total / dataIndicator.result.length) * 100;
    return total;
  };

  const ColoredCard = ({ header, content, color = "bg-primary-700" }) => (
    <div
      className={classNames(
        "card w-full shadow-sm rounded-xl border-gray-200 border-solid border-[1px]",
        color
      )}
    >
      <div className="card-body p-3">
        {header}
        <div className="card w-full bg-gray-50 bg-opacity-25 shadow-sm rounded border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">{content}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between items-center">
        <Breadcrumbs items={links} />
        <div className="card bg-primary-600 text-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-2">
            <div>
              Status Project: <span className="font-bold">Hijau</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className="flex-1">Dashboard Project</TitleText>
        <div className="flex items-center space-x-4 flex-1">
          <div className="flex-1">
            {/* <SearchInput placeholder="Pencarian" onChange={onSearch} /> */}
          </div>
          <div className="flex-1">
            <CustomSelect value={null} placeholder="Project" optionsData={[]} />
          </div>
        </div>
      </div>
      <ColoredCard
        content={
          <>
            <label className="text-white font-medium">Capaian Output</label>
            <div className="flex items-baseline gap-3 -mt-1">
              <span className="bg-white px-3 py-1 rounded font-bold text-primary-600">
                80%
              </span>
              <span className="font-bold text-white text-2xl">
                Rp. 500,000,000
              </span>
            </div>
          </>
        }
      />

      <div className="my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Total Laporan</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Total Laporan Perlu Ditinjau</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Total Laporan Disetujui</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Total Laporan Ditolak</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <ColoredCard
          color="bg-bluelight-500"
          header={
            <span className="bg-white rounded w-max p-2 mb-2">
              <FaMoneyBills className="text-xl text-bluelight-500" />
            </span>
          }
          content={
            <>
              <label className="text-white font-medium">
                Total Rencana Biaya
              </label>
              <div className="font-bold text-white text-2xl">
                Rp. 500,000,000
              </div>
            </>
          }
        />
        <ColoredCard
          color="bg-orange-400"
          header={
            <span className="bg-white rounded w-max p-2 mb-2">
              <FaMoneyBills className="text-xl text-orange-400" />
            </span>
          }
          content={
            <>
              <label className="text-white font-medium">Total Penyaluran</label>
              <div className="font-bold text-white text-2xl">
                Rp. 180,000,000
              </div>
            </>
          }
        />
        <ColoredCard
          color="bg-violet-600"
          header={
            <span className="bg-white rounded w-max p-2 mb-2">
              <FaMoneyBills className="text-xl text-violet-600" />
            </span>
          }
          content={
            <>
              <label className="text-white font-medium">
                Total Realisasi Biaya
              </label>
              <div className="font-bold text-white text-2xl">
                Rp. 500,000,000
              </div>
            </>
          }
        />
        <ColoredCard
          color="bg-[#E93636]"
          header={
            <span className="bg-white rounded w-max p-2 mb-2">
              <FaMoneyBills className="text-xl text-[#E93636]" />
            </span>
          }
          content={
            <>
              <label className="text-white font-medium">
                Outstanding Realisasi
              </label>
              <div className="font-bold text-white text-2xl">
                Rp. 500,000,000
              </div>
            </>
          }
        />
      </div>

      <div className="my-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>LEMTARA</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>KL</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Beneficiaries Perorangan</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>

        <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200 border-l-4 border-l-primary-600">
          <div className="card-body p-3">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 rounded-full p-2">
                <LuClipboardList className="text-primary-600 text-lg" />
              </div>
              <span>Implementing Partner</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg mt-2">
              <h1 className="font-bold text-2xl">130.000</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <CollapsingCard title="Pengadaan">
          <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Kinerja Pelelangan</h1>
              </div>

              <div className="bg-gray-50 rounded-lg p-3">
                <label className="text-sm font-medium">Progress</label>
                <h1 className="text-green-500 font-semibold text-3xl">75%</h1>
              </div>

              <div className="py-2">
                <LineChart />
              </div>
            </div>
          </div>
        </CollapsingCard>
        <CollapsingCard title="Teknis">
          <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Kinerja Pelelangan</h1>
              </div>

              <div className="bg-gray-50 rounded-lg p-3">
                <label className="text-sm font-medium">Progress</label>
                <h1 className="text-red-500 font-semibold text-3xl">
                  {/* {calculateIndicatorScore}% */}
                  {calculateIndicatorScore()}%
                </h1>
              </div>

              <div className="py-2">
                <BarChart
                  data={dataIndicator?.result || null}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </CollapsingCard>
      </div>

      <CollapsingCard title="Progress of Variant">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="card w-full bg-white shadow-lg rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Waktu Terpakai Hibah</h1>
              </div>
              <div className="py-2">
                <PieChart />
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-lg rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Tahun Anggaran</h1>
              </div>

              <div className="py-2">
                <PieChart />
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-lg rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Serapan Dana Proyek</h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">
                    Progress of Variant Dana
                  </label>
                  <h1 className="text-orange-500 font-semibold text-3xl">
                    0.79
                  </h1>
                </div>
                <div className="bg-orange-500 rounded-lg p-3">
                  <label className="text-white text-sm font-medium">
                    Status Progress of Variant
                  </label>
                  <h1 className="text-white font-bold text-lg uppercase mt-2">
                    Behind Schedule
                  </h1>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">Total Dana</label>
                  <h1 className="font-semibold text-2xl">
                    {formatCurrency(20_000_000)}
                  </h1>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">Total Serapan</label>
                  <h1 className="font-semibold text-2xl">
                    {formatCurrency(20_000_000)}
                  </h1>
                </div>
              </div>
              <div className="py-2">
                <LineChart />
              </div>
            </div>
          </div>
          <div className="card w-full bg-white shadow-lg rounded-lg border-gray-200">
            <div className="card-body p-3">
              <div className="border-b-2 border-b-gray-100 py-2">
                <h1 className="font-medium py-2">Serapan Tahun Anggaran</h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">
                    Progress of Variant Dana
                  </label>
                  <h1 className="text-green-500 font-semibold text-3xl">
                    0.79
                  </h1>
                </div>
                <div className="bg-green-500 rounded-lg p-3">
                  <label className="text-white text-sm font-medium">
                    Status Progress of Variant
                  </label>
                  <h1 className="text-white font-bold text-lg uppercase mt-2">
                    On Schedule
                  </h1>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">Total Dana</label>
                  <h1 className="font-semibold text-2xl">
                    {formatCurrency(20_000_000)}
                  </h1>
                </div>
                <div className="bg-gray-50 rounded-lg p-3">
                  <label className="text-sm font-medium">Total Serapan</label>
                  <h1 className="font-semibold text-2xl">
                    {formatCurrency(20_000_000)}
                  </h1>
                </div>
              </div>
              <div className="py-2">
                <LineChart />
              </div>
            </div>
          </div>
        </div>
      </CollapsingCard>

      <div className="my-5">
        <CollapsingCard title="Safeguard">
          <div className="grid grid-cols-2 gap-5">
            <div className="card w-full bg-white shadow-sm rounded-lg border-gray-200">
              <div className="card-body p-3">
                <div className="border-b-2 border-b-gray-100 py-2">
                  <h1 className="font-medium py-2">Komitmen Safeguard</h1>
                </div>

                <div className="py-2">
                  <BarChart />
                </div>
              </div>
            </div>
            <div className="card w-full bg-white shadow-lg rounded-lg border-gray-200">
              <div className="card-body p-3">
                <div className="border-b-2 border-b-gray-100 py-2">
                  <h1 className="font-medium py-2">Serapan Dana Proyek</h1>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  <div className="bg-gray-50 rounded-lg p-3">
                    <label className="text-sm font-medium">
                      Isu Lingkungan
                    </label>
                    <h1 className="text-orange-500 font-semibold text-3xl">
                      1
                    </h1>
                  </div>
                  <div className="bg-gray-50 rounded-lg p-3">
                    <label className="text-sm font-medium">Isu Sosial</label>
                    <h1 className="font-bold text-lg uppercase mt-2">2</h1>
                  </div>
                  <div className="bg-green-500 rounded-lg p-3">
                    <label className="text-white text-sm font-medium">
                      Total Isu
                    </label>
                    <h1 className="text-white font-bold text-lg uppercase mt-2">
                      3
                    </h1>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="bg-gray-50 rounded-lg p-3">
                    <label className="text-sm font-medium">Perempuan</label>
                    <h1 className="font-semibold text-2xl">
                      {(1000).toLocaleString()}
                    </h1>
                  </div>
                  <div className="bg-gray-50 rounded-lg p-3">
                    <label className="text-sm font-medium">Laki-Laki</label>
                    <h1 className="font-semibold text-2xl">
                      {(1000).toLocaleString()}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CollapsingCard>
      </div>
    </div>
  );
};

export default Dashboard;
