import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { random } from "lodash";

const PieChart = () => {
    const options = {
        chart: {
          backgroundColor: "#F9FAFB",
          type: "pie",
        },
        title: {
          text: "",
        },
        plotOptions: {
            pie: {
                shadow: false,
                dataLabels: {
                    color:'white',
                    distance: -35,
                    formatter: function () {
                        if(this.y!=0)  return Math.round(this.y)  + '%';

                    }
                }
            },
        },
        tooltip: {
            formatter: function() {
                return '<b>'+ this.point.name +'</b>: '+ this.y +' %';
            }
        },
        series: [{
            name: 'Browsers',
            // data: [["Firefox",6],["MSIE",4],["Chrome",7]],
            data: [{
                y: random(3, 50),
                color: "#00BD52",
            },{
                y: random(3, 50),
                color: "#E34F13",
            }],
            innerSize: '60%',
            showInLegend:true,
        }],
        credits: {
          enabled: false,
        },
      };
    
      return (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      );
}

export default PieChart