import { CustomSelect, Label } from "components";
import { map } from "lodash";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CgClose } from "react-icons/cg";
import { FiPlus } from "react-icons/fi";
import { useQuery } from "react-query";
import { INDICATOR } from "services";

const QuizIndicatorForm = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "indicators",
  });

  const {
    isLoading,
    data: indicatorData = [],
    refetch,
  } = useQuery({
    queryKey: [
      "indicator-options",
      {
        page: 1,
        limit: 10,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await INDICATOR.getIndicatorList({ ...params }, signal);
      return map(response?.data?.result ?? [], (data) => ({
        value: data?.id,
        label: data?.name,
      }));
    },
  });

  return (
    <div className="h-full space-y-4">
      {map(fields, (field, key) => (
        <div key={field.id} className="flex items-end space-x-4">
          <div className="flex flex-col w-full gap-2">
            <Label>Nama Indikator</Label>
            <CustomSelect
              name={`indicators.${key}.indicator`}
              placeholder="Pilih indikator"
              optionsData={indicatorData}
              className="w-full"
            />
          </div>
          {key === fields?.length - 1 ? (
            <button
              className="bg-primary-600 p-3 rounded"
              onClick={() =>
                append({
                  indicator: null,
                  questions: [
                    {
                      questionName: null,
                      questionType: null,
                      options: [
                        {
                          value: null,
                        },
                      ],
                    },
                  ],
                })
              }
            >
              <FiPlus className="text-white" />
            </button>
          ) : (
            <button
              className="bg-error-600 p-3 rounded"
              onClick={() => remove(key)}
            >
              <CgClose className="text-white" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuizIndicatorForm;
