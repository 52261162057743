import {
  Breadcrumbs,
  Button,
  CollapsingCard,
  CustomSelect,
  Label,
  Spinner,
  Table,
  TitleText,
} from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { find, first, map } from "lodash";
import { SideModal } from "components/Modal";
import QuizIndicatorForm from "./QuizIndicatorForm";
import { BiPencil, BiSave, BiSearch } from "react-icons/bi";
import QuestionSection from "./QuestionSection";
import IndicatorForm from "pages/Evaluate/Indicator/Form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { INDICATOR, WP } from "services";
import { CustomDatepickerForm, CustomSelectForm } from "components/HookForm";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { FiArrowDown, FiArrowUp, FiTrash } from "react-icons/fi";

const programOptions = [
  { value: "Tunda Tebang", label: "Tunda Tebang" },
  { value: "Off farm", label: "Off farm" },
  { value: "On farm", label: "On farm" },
];

const aTypeOptions = [
  { value: 1, label: "Checkbox" },
  { value: 2, label: "Radio" },
  { value: 3, label: "Dropdown" },
];

const bTypeOptions = [
  { value: 1, label: "Text" },
  { value: 2, label: "Number" },
  { value: 3, label: "Currency" },
];

const EvaluatePaperWorkForm = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const [openAddQuizModal, setOpenAddQuizModal] = useState(false);
  const [openAddIndicatorModal, setOpenAddIndicatorModal] = useState(false);

  // indicator pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const id = useMemo(() => location?.state?.id ?? null);

  const { isFetching, data: workingPaperDetail } = useQuery({
    queryKey: ["paper-work-detail", id],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      if (params) {
        let response = await WP.getWorkingPaperDetail(params);
        return response.result;
      }
      return null;
    },
  });

  const { data: serviceTypeOpt } = useQuery({
    queryKey: ["service-type-opt"],
    queryFn: ({ queryKey }) => {
      const params = queryKey[1];
      return WP.getWorkingPaperServiceType({ ...params });
    },
  });

  const {
    isLoading,
    data: indicatorData,
    refetch,
  } = useQuery({
    queryKey: [
      "indicator-table",
      {
        page,
        limit,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await INDICATOR.getIndicatorList({ ...params }, signal);
      return response.data;
    },
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      program: null,
      indicators: [],
      status: true,
      period: "",
    },
  });

  const { fields: indicatorFields, replace: replaceIndicator, remove, append, replace } = useFieldArray({
    control: methods.control,
    name: "indicators",
  });

  const indicators = useWatch({ control: methods.control, name: "indicators" });

  const insertAndShift = (from, to) => {
    let cutOut = indicators.splice(from, 1)[0];
    indicators.splice(to, 0, cutOut);
    replace(indicators);
  };

  const methodQuiz = useForm({
    mode: "onChange",
    defaultValues: {
      program: null,
      indicators: [
        {
          indicator: null,
          questions: [
            {
              questionName: null,
              questionType: null,
              answerType: null,
              options: [
                {
                  name: "",
                  is_correct: false,
                },
              ],
              subQuestions: [
                {
                  questionName: null,
                  questionType: null,
                  answerType: null,
                  options: [
                    {
                      name: "",
                      is_correct: false,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  });

  const onChangePage = (page) => {
    setPage(page);
    refetch();
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
    refetch();
  };

  const methodIndicator = useForm({
    defaultValues: { indicators: [{ name: "" }] },
  });

  const storeWorkingPaper = useMutation((payload) => {
    WP.createNewWorkingPaper(payload);
  });

  const updateWorkingPaper = useMutation((payload) => {
    WP.editWorkingPaper(payload);
  });

  useEffect(() => {
    methods.reset({
      program: null,
      indicators: [],
    });
    methodQuiz.reset({
      program: null,
      indicators: [
        {
          indicator: null,
          questions: [
            {
              questionName: null,
              questionType: null,
              options: [
                {
                  name: "",
                  is_correct: false,
                },
              ],
            },
          ],
        },
      ],
    });
    methodIndicator.reset({ indicators: [{ name: "" }] });
    return;
  }, []);

  useEffect(() => {
    if (workingPaperDetail) {
      methods.reset({
        period: workingPaperDetail?.period
          ? new Date(workingPaperDetail?.period)
          : "",
        status: workingPaperDetail?.status === "Active" ? true : false,
        program: {
          id: workingPaperDetail?.program_id,
          name: workingPaperDetail?.program_name,
        },
        indicators: map(workingPaperDetail?.question_data, (question_data) => ({
          indicator: {
            value: first(question_data?.questions)?.indicator_id,
            label: first(question_data?.questions)?.indicator_name,
          },
          questions: map(question_data?.questions, (question) => ({
            id: question?.id,
            questionName: question?.question,
            answerType:
              question?.question_type === "Essay"
                ? find(bTypeOptions, { value: parseInt(question?.input_type) })
                : find(aTypeOptions, { value: parseInt(question?.input_type) }),
            questionType:
              question?.question_type === "Essay"
                ? { value: "single", label: "Isian" }
                : { value: "multiple", label: "Pertanyaan Ganda" },
            options:
              question?.question_answers?.length <= 0
                ? [{ value: null }]
                : map(question?.question_answers, (answer) => ({
                    name: answer.name,
                    is_correct: answer.is_correct,
                  })),
            subQuestions:
              question?.sub_questions?.length <= 0
                ? []
                : map(question?.sub_questions, (subQuest) => ({
                    id: subQuest?.id,
                    questionName: subQuest?.question,
                    answerType:
                      subQuest?.question_type === "Essay"
                        ? find(bTypeOptions, {
                            value: parseInt(subQuest?.input_type),
                          })
                        : find(aTypeOptions, {
                            value: parseInt(subQuest?.input_type),
                          }),
                    questionType:
                      subQuest?.question_type === "Essay"
                        ? { value: "single", label: "Isian" }
                        : { value: "multiple", label: "Pertanyaan Ganda" },
                    options:
                      subQuest?.question_answers?.length <= 0
                        ? []
                        : map(subQuest?.question_answers, (subAnswer) => ({
                            name: subAnswer.name,
                            is_correct: subAnswer.is_correct,
                          })),
                  })),
          })),
        })),
      });
    }
  }, [workingPaperDetail]);

  const onSubmit = (payload) => {
    let params = {
      program_id: payload?.program?.id ?? "",
      program_name: payload?.program?.name ?? "",
      status: payload?.status ? "Active" : "Inactive",
      period: payload?.period ? moment(payload?.period).format("YYYY") : "",
      question_data: map(payload?.indicators ?? [], (indicator) => ({
        indicator_id: indicator?.indicator?.value,
        indicator_name: indicator?.indicator?.label,
        questions: map(indicator?.questions, (question) => {
          if (question?.questionType?.value === "multiple") {
            return {
              question: question?.questionName,
              answer: map(question?.options, (opt) => ({
                name: opt.name,
                is_correct: opt.is_correct,
              })),
              question_type: "Multiple",
              input_type: question?.answerType?.value ?? null,
              notes: null,
              sub_questions: map(question?.subQuestions, (sub) => {
                if (sub?.questionType?.value === "multiple") {
                  return {
                    question: sub?.questionName,
                    answer: map(sub?.options, (opt) => ({
                      name: opt.name,
                      is_correct: opt.is_correct,
                    })),
                    question_type: "Multiple",
                    input_type: sub?.answerType?.value ?? null,
                    notes: null,
                  };
                } else if (sub?.questionType?.value === "single") {
                  return {
                    question: sub?.questionName,
                    answer: map(sub?.options, (opt) => ({
                      name: opt.name,
                      is_correct: opt.is_correct,
                    })),
                    question_type: "Essay",
                    input_type: sub?.answerType?.value ?? null,
                    notes: null,
                  };
                }
              }),
            };
          } else if (question?.questionType?.value === "single") {
            return {
              question: question?.questionName,
              answer: map(question?.options, (opt) => ({
                name: opt.name,
                is_correct: opt.is_correct,
              })),
              question_type: "Essay",
              input_type: question?.answerType?.value ?? null,
              notes: null,
              sub_questions: map(question?.subQuestions, (sub) => {
                if (sub?.questionType?.value === "multiple") {
                  return {
                    question: sub?.questionName,
                    answer: map(sub?.options, (opt) => ({
                      name: opt.name,
                      is_correct: opt.is_correct,
                    })),
                    question_type: "Multiple",
                    input_type: sub?.answerType?.value ?? null,
                    notes: null,
                  };
                } else if (sub?.questionType?.value === "single") {
                  return {
                    question: sub?.questionName,
                    answer: map(sub?.options, (opt) => ({
                      name: opt.name,
                      is_correct: opt.is_correct,
                    })),
                    question_type: "Essay",
                    input_type: sub?.answerType?.value ?? null,
                    notes: null,
                  };
                }
              }),
            };
          }
        }),
      })),
    };

    if (id) {
      params = {
        ...params,
        question_data: map(payload?.indicators ?? [], (indicator) => ({
          indicator_id: indicator?.indicator?.value,
          indicator_name: indicator?.indicator?.label,
          questions: map(indicator?.questions, (question) => {
            if (question?.questionType?.value === "multiple") {
              return {
                id: question?.id ?? null,
                question: question?.questionName,
                answer: map(question?.options, (opt) => ({
                  name: opt.name,
                  is_correct: opt.is_correct,
                })),
                question_type: "Multiple",
                input_type: question?.answerType?.value ?? null,
                notes: null,
                indicator_id: indicator?.indicator?.value,
                indicator_name: indicator?.indicator?.label,
                sub_questions: map(question?.subQuestions, (sub) => {
                  if (sub?.questionType?.value === "multiple") {
                    return {
                      question: sub?.questionName,
                      answer: map(sub?.options, (opt) => ({
                        name: opt.name,
                        is_correct: opt.is_correct,
                      })),
                      question_type: "Multiple",
                      input_type: sub?.answerType?.value ?? null,
                      notes: null,
                    };
                  } else if (sub?.questionType?.value === "single") {
                    return {
                      question: sub?.questionName,
                      answer: map(sub?.options, (opt) => ({
                        name: opt.name,
                        is_correct: opt.is_correct,
                      })),
                      question_type: "Essay",
                      input_type: sub?.answerType?.value ?? null,
                      notes: null,
                    };
                  }
                }),
              };
            } else if (question?.questionType?.value === "single") {
              return {
                id: question?.id ?? null,
                question: question?.questionName,
                answer: map(question?.options, (opt) => ({
                  name: opt.name,
                  is_correct: opt.is_correct,
                })),
                question_type: "Essay",
                input_type: question?.answerType?.value ?? null,
                notes: null,
                indicator_id: indicator?.indicator?.value,
                indicator_name: indicator?.indicator?.label,
                sub_questions: map(question?.subQuestions, (sub) => {
                  if (sub?.questionType?.value === "multiple") {
                    return {
                      question: sub?.questionName,
                      answer: map(sub?.options, (opt) => ({
                        name: opt.name,
                        is_correct: opt.is_correct,
                      })),
                      question_type: "Multiple",
                      input_type: sub?.answerType?.value ?? null,
                      notes: null,
                    };
                  } else if (sub?.questionType?.value === "single") {
                    return {
                      question: sub?.questionName,
                      answer: map(question?.options, (opt) => ({
                        name: opt.name,
                        is_correct: opt.is_correct,
                      })),
                      question_type: "Essay",
                      input_type: sub?.answerType?.value ?? null,
                      notes: null,
                    };
                  }
                }),
              };
            }
          }),
        })),
      };
      updateWorkingPaper.mutate(
        { id, payload: params },
        {
          onSuccess: (data, variables) => {
            enqueueSnackbar({
              message: `Berhasil mengubah data`,
              variant: "success",
            });
            queryClient.invalidateQueries("working-paper");
            navigate("/evaluate-paper-work");
          },
          onError: (error, variables) => {
            enqueueSnackbar({
              message: `Something went wrong`,
              variant: "error",
            });
          },
        }
      );
    } else {
      storeWorkingPaper.mutate(params, {
        onSuccess: (data, variables) => {
          enqueueSnackbar({
            message: `Berhasil menambahkan data`,
            variant: "success",
          });
          queryClient.invalidateQueries("working-paper");
          navigate("/evaluate-paper-work");
        },
        onError: (error, variables) => {
          enqueueSnackbar({
            message: `Something went wrong`,
            variant: "error",
          });
        },
      });
    }
  };

  const links = [
    {
      label: "Evaluasi",
      path: "#",
    },
    {
      label: "Kertas Kerja",
      path: "/evaluate-paper-work",
    },
    {
      label: id ? "Edit" : "Tambah",
    },
  ];

  const submitQuiz = useCallback((data) => {
    replaceIndicator(data.indicators);
    setOpenAddQuizModal(false);
  }, []);

  const submitIndicator = useCallback((data) => {
    replaceIndicator(data.indicators);
    setOpenAddQuizModal(false);
  }, []);

  const onClickAddQuiz = () => {
    setOpenAddQuizModal(true);
    if (indicators?.length > 0) {
      methodQuiz.reset({ indicators: indicators });
    }
  };

  const onClickAddIndicator = () => {
    setOpenAddIndicatorModal(true);
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(indicatorData?.paginator?.page) *
            parseInt(indicatorData?.paginator?.limit) -
          parseInt(indicatorData?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "List Indikator",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
  ];

  return (
    <div>
      <SideModal
        title="Tambah List Indikator"
        open={openAddQuizModal}
        onClose={() => setOpenAddQuizModal(false)}
        onSubmit={methodQuiz.handleSubmit(submitQuiz)}
        width="w-1/3"
        position="center-right"
        modalClassName="w-1/3 h-screen rounded-none"
        btnLabelSubmit="Tambah Indikator"
      >
        <FormProvider {...methodQuiz}>
          <QuizIndicatorForm indicatorData={indicatorData} />
        </FormProvider>
      </SideModal>
      <SideModal
        title={"Tambah List Indikator"}
        open={openAddIndicatorModal}
        onClose={() => setOpenAddIndicatorModal(false)}
        onSubmit={methodIndicator.handleSubmit(submitIndicator)}
        width="w-1/3"
        position="center-right"
        modalClassName="w-1/3 h-screen rounded-none"
        btnLabelSubmit="Tambah Indikator"
      >
        <FormProvider {...methodIndicator}>
          <IndicatorForm />
        </FormProvider>
      </SideModal>
      <FormProvider {...methods}>
        <div className="space-y-6">
          <Breadcrumbs items={links} />
          <div className="md:flex block items-center my-3 justify-between">
            <TitleText className="flex-1">
              {id ? "Perbarui" : "Tambah"} Kertas Kerja
            </TitleText>
            {indicators?.length <= 0 ? (
              <Button
                className="bg-primary-800 border-primary-800 text-white hover:bg-primary-800/90 hover:border-primary-800/90"
                size="md"
                startIcon={<AiOutlinePlus />}
                onClick={onClickAddQuiz}
              >
                {"Tambah Kuesioner"}
              </Button>
            ) : (
              <Button
                className="bg-primary-800 border-primary-800 text-white hover:bg-primary-800/90 hover:border-primary-800/90"
                size="md"
                startIcon={<BiSave />}
                onClick={methods.handleSubmit(onSubmit)}
              >
                {"Simpan Kertas Kerja"}
              </Button>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center flex-1 gap-4">
              <div className="flex flex-col w-1/4 gap-2">
                <Label>Nama Kegiatan</Label>
                <CustomSelectForm
                  name={"program"}
                  placeholder="Pilih Nama Kegiatan"
                  options={serviceTypeOpt?.result}
                  getOptionLabel={(opt) => opt.name}
                  getOptionValue={(opt) => opt.id}
                  className="w-full"
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="flex flex-col w-1/3 gap-2">
                <Label>Periode</Label>
                <CustomDatepickerForm
                  name={"period"}
                  showYearPicker
                  placeholder={"Pilih Periode"}
                  dateFormat="yyyy"
                  onClear={() => methods.setValue("period", "")}
                />
              </div>
            </div>
            <div className="flex flex-none flex-col gap-2">
              <Label>Status</Label>
              <label>
                <input
                  {...methods.register("status")}
                  type="checkbox"
                  className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md hidden"
                />
                <span className="w-10 h-6 flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-primary-600 after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4"></span>
              </label>
            </div>
          </div>

          {indicatorFields?.length <= 0 ? (
            indicatorData && !isLoading ? (
              <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
                <div className="card-body p-3">
                  <div className="space-y-4">
                    <Table
                      bordered
                      stripped
                      layout="fixed"
                      className="mb-4"
                      columns={tableColumns}
                      dataSource={indicatorData?.result}
                      isLoading={isLoading}
                      onChangePage={onChangePage}
                      onChangeRowsPerPage={onChangeRowsPerPage}
                      initialRowPerPage={limit}
                      initialPage={page}
                      pagination={indicatorData?.paginator}
                    />
                  </div>
                </div>
              </div>
            ) : isLoading ? (
              <div className="flex flex-col items-center justify-center w-full">
                <Spinner />
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center space-y-4 pt-16">
                <div className="bg-primary-50 rounded-full p-2">
                  <div className="bg-primary-100 rounded-full p-2">
                    <BiSearch className="text-primary-600 text-2xl" />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center space-y-1">
                  <p className="font-semibold text-gray-900">
                    Belum ada indikator penilaian
                  </p>
                  <p className="text-sm text-gray-600 max-w-xs text-center">
                    Tambahkan indikator penilaian dengan klik button dibawah ini
                  </p>
                </div>
                <Button
                  className="bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  size="md"
                  startIcon={<AiOutlinePlus />}
                  onClick={onClickAddIndicator}
                >
                  Tambah Indikator
                </Button>
              </div>
            )
          ) : null}

          {indicatorFields?.length > 0 &&
            !isFetching &&
            map(indicatorFields, (field, key) => (
              <CollapsingCard
                key={field.id}
                title={
                  <div className="flex items-center gap-4">
                    <span>{field?.indicator?.label}</span>
                    <div className="flex gap-2">
                      <Button
                        size="md"
                        startIcon={<FiArrowUp />}
                        disabled={key === 0}
                        className={
                          key === 0
                            ? "bg-[#E0E0E0] border-[#E0E0E0] text-[#292D32] hover:text-white"
                            : "bg-white border-primary-300 text-primary-800 hover:text-white"
                        }
                        onClick={() => insertAndShift(key, key - 1)}
                      />
                      <Button
                        size="md"
                        startIcon={<FiArrowDown />}
                        disabled={key === indicators.length - 1}
                        className={
                          key === indicators.length - 1
                            ? "bg-[#E0E0E0] border-[#E0E0E0] text-[#292D32] hover:text-white"
                            : "bg-white border-primary-300 text-primary-800 hover:text-white"
                        }
                        onClick={() => insertAndShift(key, key + 1)}
                      />
                      {key > 0 && (
                        <Button
                          size="md"
                          startIcon={<FiTrash />}
                          className="bg-error-600 border-error-600"
                          onClick={() => remove(key)}
                        />
                      )}
                    </div>
                  </div>
                }
              >
                <QuestionSection name={`indicators.${key}`} />
              </CollapsingCard>
            ))}

          <div className="w-full flex items-center justify-center space-x-2">
            {indicators?.length > 0 && !isFetching && (
              <Button
                className="bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
                size="md"
                startIcon={<AiOutlinePlus />}
                onClick={onClickAddQuiz}
              >
                {"Tambah Kuesioner"}
              </Button>
            )}
            {!isLoading && (
              <Button
                className="bg-gray-600 text-white border-gray-600"
                size="md"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                {"Back To Top"}
              </Button>
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default EvaluatePaperWorkForm;
