import React from "react";
import { Label } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import { useFormContext } from "react-hook-form";

const qTypeOptions = [
  { value: "single", label: "Isian" },
  { value: "multiple", label: "Pertanyaan Ganda" },
];

const aTypeOptions = [
  { value: 1, label: "Checkbox" },
  { value: 2, label: "Radio" },
  { value: 3, label: "Dropdown" },
];

const bTypeOptions = [
  { value: 1, label: "Text" },
  { value: 2, label: "Number" },
  { value: 3, label: "Currency" },
]

const Form = ({ name }) => {
  const methods = useFormContext();

  return (
    <div className="flex gap-4">
      <div className="flex flex-col flex-1 gap-1">
        <Label>Nama Penilaian</Label>
        <InputForm name={`${name}.questionName`} className="w-full" />
      </div>

      <div className="flex flex-col flex-1 gap-1">
        <Label>Jenis Pertanyaan</Label>
        <CustomSelectForm
          name={`${name}.questionType`}
          placeholder="Pilih Jenis Pertanyaan"
          options={qTypeOptions}
          className="w-full"
        />
      </div>

      {methods.watch(`${name}.questionType`)?.value === "multiple" && (
        <div className="flex flex-col flex-1 gap-1">
          <Label>Jenis Jawaban</Label>
          <CustomSelectForm
            name={`${name}.answerType`}
            placeholder="Pilih Jenis Jawaban"
            options={aTypeOptions}
            className="w-full"
          />
        </div>
      )}

      {methods.watch(`${name}.questionType`)?.value === "single" && (
        <div className="flex flex-col flex-1 gap-1">
          <Label>Jenis Jawaban</Label>
          <CustomSelectForm
            name={`${name}.answerType`}
            placeholder="Pilih Jenis Jawaban"
            options={bTypeOptions}
            className="w-full"
          />
        </div>
      )}
    </div>
  );
};

export default Form;
