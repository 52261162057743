import Assignment from "pages/Assignment";

const assignmentRoutes = [
  {
    routeType: "public",
    path: "/assignment-program",
    element: Assignment,
    exact: true,
    type: "canView",
    key: "assignment-program",
  },
];

export default assignmentRoutes;
