import Dashboard from "pages/Dashboard";

const dashboardRoutes = [
  {
    routeType: "public",
    path: "/program-dashboard",
    element: Dashboard,
    exact: true,
    key: "program-dashboard",
    type: "canView",
  },
];

export default dashboardRoutes;
