import React from "react";
import numberToText from "number-to-text";
import "number-to-text/converters/en-us";

const ApplicationData = ({
  amount_of_loan = "-",
  period = "-",
  financing_scheme = "-",
}) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Dana Peminjaman :</div>
            <div className="text-sm font-[400]">{amount_of_loan}</div>
            <div className="text-sm font-[700]">Skema Pembiayaan :</div>
            <div className="text-sm font-[400]">{financing_scheme}</div>
            <div className="text-sm font-[700]">
              Jumlah anggota yg mengajukan permohonan :
            </div>
            <div className="text-sm font-[400]">{"-"}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Terbilang :</div>
            <div className="text-sm font-[400]">
              {numberToText.convertToText(amount_of_loan)}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Jangka Waktu :</div>
            <div className="text-sm font-[400]">{period}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationData;
