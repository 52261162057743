import { Button, Input, Label, SearchInput, Table } from "components";
import { InputForm } from "components/HookForm";
import { FormModal } from "components/Modal";
import useGetListTable from "hooks/useGetListTable";
import {
  useApproveEvaluation,
  useUpdateApprovalEvaluation,
} from "hooks/useMonitoring";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";
import { EVALUATION } from "services";

const renderStatus = (status) => {
  if (status?.toLowerCase() === "baik") {
    return (
      <span className="py-1 px-2 text-sm bg-green-50 text-green-500 rounded-3xl">
        {status}
      </span>
    );
  } else if (status?.toLowerCase() === "kurang baik") {
    return (
      <span className="py-1 px-2 text-sm bg-yellow-50 text-yellow-500 rounded-3xl">
        {status}
      </span>
    );
  } else if (status?.toLowerCase() === "tidak baik") {
    return (
      <span className="py-1 px-2 text-sm bg-red-50 text-red-500 rounded-3xl">
        {status}
      </span>
    );
  } else {
    return <span className="py-1 px-2 text-sm rounded-3xl">{status}</span>;
  }
};

const MonitoringTable = ({ status }) => {
  const navigate = useNavigate();

  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenDecline, setIsOpenDecline] = useState(false);
  const [reason, setReason] = useState(false);
  const [dataSelected, setDataSelected] = useState();

  const [isReasonChecked, setIsReasonChecked] = useState(false);

  const {
    data: dataEvaluasi,
    isLoading,
    setOrderBy,
    setOrderDirection,
    setKeyword,
    setPage,
    onChangePage,
    onChangeRowsPerPage,
    onSearch,
    refetch,
  } = useGetListTable("evaluation-list", EVALUATION.getEvaluation, {
    status,
  });

  const updateApproval = useUpdateApprovalEvaluation();

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(dataEvaluasi?.paginator?.page) *
            parseInt(dataEvaluasi?.paginator?.limit) -
          parseInt(dataEvaluasi?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "debtor-request-nap",
      title: "NAP",
      dataIndex: "debtor",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("nap");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value?.request?.nap : "-"}
        </div>
      ),
    },
    {
      id: "debtor-user-name",
      title: "Nama Penerima FDB",
      dataIndex: "debtor",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("name");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value?.group_profile_member?.name : "-"}
        </div>
      ),
    },
    {
      id: "final-assesment",
      title: "Penilaian Akhir",
      dataIndex: "result",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("name");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? renderStatus(value) : "-"}
        </div>
      ),
    },
    {
      id: "debtor-user-name",
      title: "PL",
      dataIndex: "debtor",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (data, direction) => {
        setOrderBy("name");
        setOrderDirection(direction);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? value?.user?.name : "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: () => <div className="text-center">Action</div>,
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      hasCheckbox: true,
      // columnClassName: "text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-full">
          {status === 0 ? (
            <div className="flex items-center w-full">
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => navigate(`/monitoring/detail/${data.id}`)}
              />
              <BiCheckCircle
                className="text-emerald-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onApprove(data)}
              />
              <CgClose
                className="text-red-600 cursor-pointer flex-1"
                size={20}
                onClick={() => onDecline(data)}
              />
            </div>
          ) : (
            <div className="flex items-center w-full">
              <HiOutlineEye
                className="text-gray-600 cursor-pointer flex-1"
                size={20}
                onClick={() => navigate(`/monitoring/detail/${data.id}`)}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const onApprove = (data) => {
    setIsOpenApprove(true);
    setDataSelected(data);
  };

  const onDecline = (data) => {
    setIsOpenDecline(true);
    setDataSelected(data);
  };

  const onUpdateApproval = () => {
    const payload = {
      is_approve: isOpenApprove ? true : false,
      reject_reason: isOpenApprove ? "" : reason,
    };

    updateApproval.mutate(
      { id: dataSelected?.id, payload },
      {
        onSuccess: () => {
          if (isOpenApprove) {
            enqueueSnackbar({
              message: `Laporan telah disetujui`,
              variant: "success",
            });

            setIsOpenApprove(false);
          } else {
            enqueueSnackbar({
              message: `Berhasil tolak laporan. Laporan yang ditolak akan masuk list investigasi`,
              variant: "success",
            });

            setIsOpenDecline(false);
          }

          refetch();
          setDataSelected(null);
        },
        onError: (error) => {
          console.log({ error });
        },
      }
    );
  };

  const renderModal = () => {
    return (
      <>
        <FormModal
          open={isOpenApprove}
          onClose={setIsOpenApprove}
          onSubmit={onUpdateApproval}
          width="w-1/3"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Setujui Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>
          </div>
        </FormModal>
        <FormModal
          open={isOpenDecline}
          onClose={setIsOpenDecline}
          onSubmit={onUpdateApproval}
          width="w-1/3"
          btnLabelSubmit="Tolak"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <p className="text-lg font-bold">Tolak Laporan?</p>
              <p className="text-sm text-[#475467]">
                Pastikan data sudah sesuai
              </p>
            </div>

            <div className="flex flex-col gap-1">
              <Label>Alasan Ditolak</Label>
              <Input
                type="textarea"
                className="w-full"
                name="reason-decline"
                placeholder="Masukkan alasan ditolak"
                onChange={(event) => setReason(event.target.value)}
              />
            </div>
          </div>
        </FormModal>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {renderModal()}
      <div className="flex items-center space-x-4 flex-1">
        <div className="flex-1">
          <SearchInput placeholder="Pencarian" onChange={onSearch} />
        </div>
      </div>

      <div className="flex flex-1 gap-2">
        <Button
          className="w-1/2 border-white bg-white text-red-500 hover:bg-white hover:border-white hover:text-red-700"
          disabled={true}
        >
          Tolak
        </Button>
        <Button
          className="w-1/2 text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
          disabled={true}
        >
          Disetujui
        </Button>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border border-gray-200">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-3"
              columns={tableColumns}
              dataSource={dataEvaluasi?.result}
              pagination={dataEvaluasi?.paginator}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitoringTable;
