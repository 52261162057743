import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const CheckboxForm = ({ id, name, label }) => {
  const {
    control,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: {onChange, value, onBlur}, fieldState: {invalid, error} }) => (
        <div className="flex items-center gap-1">
          <input id={id} type="checkbox" className="checkbox checkbox-xs rounded-sm" checked={value} onChange={onChange} />
          <label htmlFor={id} className="font-normal text-sm">{label}</label>
        </div>
      )}
    />
  )
}

export default CheckboxForm