import React from "react";

const Location = ({
  data
}) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-2 gap-y-2 lg:gap-y-4">
            <div className="flex items-center gap-3">
              <div className="text-sm font-bold">Alamat :</div>
              <div className="text-sm font-normal">{data?.address ?? '-'}</div>
            </div>
            <div className="flex items-center gap-3">
              <div className="text-sm font-bold">Kawasan Konservasi :</div>
              <div className="text-sm font-normal">
                {data?.is_conservation_area ? 'Ya' : 'Tidak'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
