import React from "react";
import { AuthConsumer } from "providers/authProvider";
import { Spinner } from "components";

const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return (
        <div className="relative min-h-screen">
          <Spinner />
        </div>
      );
    }}
  </AuthConsumer>
);

export default SilentRenew;