import config from "app/config";
import axiosInstance from "app/interceptors";

export const getNotificationList = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/notification`,
    { params, signal }
  );
};

export const putNotificationRead = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/notification/read`,
    params
  );
};
