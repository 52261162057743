import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

const BarChart = () => {
  const options = {
    chart: {
      type: "column",
      backgroundColor: "#EEFFF4",
    },
    colors: ["#2EFA88", "#00BD52"],
    title: {
      text: "Total debitur & kelompok per periode ini",
      align: "left",
    },
    // subtitle: {
    //   text:
    //     'Source: <a target="_blank" ' +
    //     'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
    //   align: "left",
    // },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      // title: {
      //   text: "1000 metric tons (MT)",
      // },
    },
    tooltip: {
      valueSuffix: " (1000 MT)",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Debitur",
        data: [387749, 280000, 129000, 64300, 54000, 34300, 45321, 140000, 10000, 140500, 19500, 113500],
      },
      {
        name: "Kelompok",
        data: [45321, 140000, 10000, 140500, 19500, 113500, 387749, 280000, 129000, 64300, 54000, 34300],
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
