import EvaluatePlanning from "pages/Evaluate/Planning"

const planningRoutes = [
  {
    routeType: "public",
    path: "/evaluate-planning",
    element: EvaluatePlanning,
    exact: true,
    type: "canView",
    key: "evaluate-planning",
  },
]

export default planningRoutes