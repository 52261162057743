import React from "react";
import numberToText from "number-to-text";
import "number-to-text/converters/en-us";

const GeneralInformation = ({ data }) => {
  return (
    <div className="flex-1 space-y-8">
      <div className="flex justify-between flex-1">
        <div className="flex flex-col flex-1 space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 space-x-5 lg:gap-y-4 lg:gap-x-2">
            <div>
              <div className="flex items-center gap-5">
                <div className="text-sm font-bold">Thematic :</div>
                <div className="text-sm font-normal">
                  {data?.thematic_programmes ?? "-"}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <div className="text-sm font-bold">Strategi Program :</div>
                <div className="text-sm font-normal">
                  {data?.strategy_program ?? "-"}
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-5">
                <div className="text-sm font-bold">Outcome :</div>
                <div className="text-sm font-normal">{data?.outcome ?? "-"}</div>
              </div>
              <div className="flex items-center gap-5">
                <div className="text-sm font-bold">Output :</div>
                <div className="text-sm font-normal">{data?.output ?? "-"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
