import React from "react";
import { Label } from "components";

const My403Component = () => {
  return (
    <div className="flex flex-col gap-2 items-center justify-center w-screen h-screen">
      <label className="text-2xl font-bold">You are not allowed to access this page</label>
      <a href="/" className="text-sm text-blue-500 font-medium underline">Back to home</a>
    </div>
  );
};

export default My403Component;
