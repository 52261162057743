import React, { useState } from "react";
import classNames from "classnames";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const TableHeader = ({
  items,
  headerClassName,
  defaultSortOrder,
  onCheckAll,
  isCheckedAll,
}) => {
  const [sortOrder, setSortOrder] = useState(
    defaultSortOrder?.toLowerCase() ?? "asc"
  );

  const getValue = (value) => {
    if (typeof value === "string") return value;
    if (typeof value === "function") return value();
    return "";
  };

  if (!items.length) return null;
  return (
    <thead className={headerClassName}>
      <tr>
        {items.map((item, key) => {
          const isLastColumn = key === items.length - 1;
          return (
            <th
              key={key}
              className={classNames(
                [
                  "bg-primary-800",
                  "text-white",
                  "text-sm",
                  "font-semibold",
                  "capitalize",
                  "sticky top-[-0.5px]",
                ],
                { "text-left": item?.alignment === "left" },
                {
                  "text-center":
                    !item?.alignment || item?.alignment === "center",
                },
                { "rounded-tl-lg": key === 0 },
                { "rounded-tr-lg": isLastColumn },
                { "text-right": item?.alignment === "right" },
                { sticky: Boolean(item?.fixed) },
                { "right-0": item?.fixed && item?.fixed === "right" },
                { "left-0": item?.fixed && item?.fixed === "left" },
                item.columnClassName && [...item.columnClassName.split(" ")],
              )}
            >
              <div className="flex items-center">
                {(item?.sortable && item?.sorter) ? (
                  <div
                    className="gap-2 flex cursor-poiner"
                    onClick={() => {
                      let direction = sortOrder === "asc" ? "desc" : "asc";

                      setSortOrder(direction);
                      item?.sorter(item.dataIndex, direction);
                    }}
                  >
                    {getValue(item.title)}
                    {sortOrder === "asc" ? (
                      <BsArrowUpShort
                        size={20}
                        className="text-gray-700 cursor-pointer"
                      />
                    ) : (
                      <BsArrowDownShort
                        size={20}
                        className="text-gray-700 cursor-pointer"
                      />
                    )}
                  </div>
                ) : (
                  getValue(item.title)
                )}
                {(isLastColumn && item.hasCheckbox) && (
                  <div className="flex items-center ml-2">
                    <input
                      type="checkbox"
                      id="custom-checkbox"
                      className="hidden peer"
                      checked={isCheckedAll}
                      onChange={onCheckAll}
                    />
                    <label
                      for="custom-checkbox"
                      className="border-[#D0D5DD] border-[1px] relative w-4 h-4 bg-white rounded cursor-pointer flex items-center justify-center peer-checked:bg-primary-50 peer-checked:border-primary-600 peer-checked:border-[1px]"
                    >
                      {isCheckedAll ? (
                        <svg
                          className="w-4 h-4  peer-checked:block"
                          width="11"
                          height="8"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 1L4 6.5L1.5 4"
                            stroke="#00BD52"
                            stroke-width="1.6666"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : null}
                    </label>
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.memo(TableHeader);
