import React, { useEffect, useState } from "react";
import classNames from "classnames";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { Spinner, Pagination } from "components";

const CustomTable = ({
  columns = [],
  dataSource = [],
  isLoading = false,
  stripped = false,
  wrapperClassName = "",
  headerClassName = "",
  className = "",
  layout = "auto",
  defaultSortOrder = undefined,
  pagination = undefined,
  onChangeRowsPerPage = undefined,
  onChangePage = undefined,
  initialRowPerPage = 10,
  initialPage = 1,
  hidePagination,
  onIdSelected = () => {},
}) => {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(dataSource.map((item) => ({ ...item, checked: false })));
  }, [dataSource]);

  useEffect(() => {
    if (data.length === 0) setIsCheckedAll(false);
    const selectedIds = data.filter((data) => data.checked).map((e) => e.id);
    onIdSelected(selectedIds);
  }, [data]);

  const handleCheckAll = () => {
    const newCheckedState = !isCheckedAll;
    setIsCheckedAll(newCheckedState);

    setData(data.map((item) => ({ ...item, checked: newCheckedState })));
  };

  const handleRowCheck = (key) => {
    const newData = [...data];
    newData[key].checked = !newData[key].checked;

    // Update state isCheckedAll jika semua row sudah dicentang
    const allChecked = newData.every((item) => item.checked);
    setIsCheckedAll(allChecked);

    setData(newData);
  };

  return (
    <div className="border border-solid border-gray-200 rounded-md rounded-tl-lg rounded-tr-lg">
      <div
        className={classNames(
          ["overflow-x-auto", "overflow-y-hidden"],
          [...wrapperClassName.split(" ")]
        )}
      >
        <table
          className={classNames(
            ["table min-w-full"],
            { "table-auto": layout === "auto" },
            { "table-fixed": layout === "fixed" },
            {
              "table-striped": stripped,
            },
            [...className.split(" ")]
          )}
        >
          {layout === "fixed" && <TableColumn items={columns} />}
          <TableHeader
            items={columns}
            headerClassName={headerClassName}
            defaultSortOrder={defaultSortOrder}
            onCheckAll={handleCheckAll}
            isCheckedAll={isCheckedAll}
          />
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="w-100 h-[300px]">
                    <Spinner className="text-xl" />
                  </div>
                </td>
              </tr>
            ) : (
              <TableRow
                columns={columns}
                datas={data}
                onRowCheck={handleRowCheck}
                isCheckedAll={isCheckedAll}
              />
            )}
          </tbody>
        </table>
      </div>
      {!hidePagination && (
        <Pagination
          pagination={pagination}
          onChangeRowsPerPage={onChangeRowsPerPage}
          initialRowPerPage={initialRowPerPage}
          onChangePage={onChangePage}
          initialPage={initialPage}
        />
      )}
    </div>
  );
};

export default React.memo(CustomTable);
