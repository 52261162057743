import { yupResolver } from "@hookform/resolvers/yup";
import { Table } from "components";
import { SideModal } from "components/Modal";
import { useUploadFile } from "hooks/externalService";
import { useAssignmentFDBQuery, useAssignmentPatch } from "hooks/useAssignmentFDB";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useSelector } from "react-redux";
import * as yup from "yup";
import PICForm from "../Form/pic-form";
import PICDetail from "../Detail/pic-detail";

const DEFAULT_PENUGASAN = {
  assignment_number: "",
  name: "",
  nik: "",
  phone_number: "",
  start_date: null,
};

const PICTable = ({ keyword, filter_region }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const uploadFile = useUploadFile();

  const schema = yup.object({
    assignment_number: yup.string().required("Tidak boleh kosong"),
    area: yup.array().min(1, "Pilih minimal satu").required("Tidak boleh kosong"),
    start_date: yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required("Tidak boleh kosong"),
    document: yup
      .mixed()
      .test("required", "Tidak boleh kosong", function (value) {
        return !!value;
      })
      .test({
        message: "File tidak boleh lebih dari 10MB",
        test: (value) => {
          return value?.[0]?.size <= 10 * 1024 * 1024; // 10MB in bytes
        }
      })
      .test({
        message: "Format file hanya boleh PDF",
        test: (value) => {
          const supportedFormats = ["pdf"];
          return supportedFormats.includes(
            value?.[0]?.name.split(".").pop().toLowerCase()
          );
        }
      })
  });

  const methodsPenugasan = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_PENUGASAN,
  });

  const {
    data: assignmentData,
    isLoading,
    refetch,
  } = useAssignmentFDBQuery([
    "assignment-list",
    { page, limit, role: "PIC,KDIV", search: keyword, filter_region, sortBy: sortBy, sort: sortType },
  ]);

  const editPenugasan = useAssignmentPatch();

  const { reset: resetPenugasan } = methodsPenugasan;

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "w-fit",
      render: (value, data, index) => {
        const no =
          parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[140px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "nik",
      title: "NIK",
      dataIndex: "nik",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      sorter: (column, methods) => {
        setSortBy(column);
        setSortType(methods);
      },
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "name",
      title: "Nama PIC",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "area",
      title: "Wilayah Kerja",
      dataIndex: "area",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "total_evaluation_approved",
      title: "Total Disetujui",
      dataIndex: "total_evaluation_approved",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "total_evaluation_rejected",
      title: "Total Ditolak",
      dataIndex: "total_evaluation_rejected",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Action",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      fixed: "right",
      render: (value, data, index) => (
        <div className="flex items-center w-fit gap-4">
          <HiOutlineEye
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => {
              onOpenDetail(data);
            }}
          />

          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onOpenEdit(data)}
          />
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const onOpenEdit = (data) => {
    setSelectedDetail(data?.user_id);
    setIsOpenEdit(true);

    resetPenugasan({
      name: data.name,
      nik: data.nik,
    });
  };

  const onOpenDetail = (data) => {
    setSelectedDetail(data);
    setIsOpenDetail(true);
  };

  const onSubmit = async (data) => {
    const { assignment_number, start_date, area } = data;

    const payload = {
      start_date: start_date ? moment(start_date).format("YYYY-MM-DD") : null,
      area: area?.length > 0 ? area.map((area) => area.label) : "",
      assignment_number,
      assign_by: user.UserId,
    };

    // Form Data for uploading File
    const formDataAssignment = new FormData();

    formDataAssignment.append("file", data?.document[0]);
    const uploadAttachment = uploadFile.mutateAsync(formDataAssignment);
    const uploadData = await Promise.all([uploadAttachment]);

    try {
      const params = {
        id: selectedDetail,
        payload: {
          ...payload,
          document: uploadData[0].data.url,
        },
      };

      editPenugasan.mutate(params, {
        onSuccess: (data, variables) => {
          setIsOpenEdit(false);
          setSelectedDetail(null);
          resetPenugasan(DEFAULT_PENUGASAN);
          refetch();
          enqueueSnackbar({
            message: `Berhasil mengubah data`,
            variant: "success",
          });
        },
      });
    } catch (error) {
      enqueueSnackbar({
        message: `Gagal Upload`,
        variant: "error",
      });
    }
  };

  const renderModal = () => {
    return (
      <Fragment>
        {/* Modal Pindah Tugas */}
        <SideModal
          title="Pindah Tugas PIC"
          open={isOpenEdit}
          onClose={() => {
            setIsOpenEdit(false);
            setSelectedDetail(null);
            resetPenugasan();
          }}
          onSubmit={methodsPenugasan.handleSubmit(onSubmit)}
          width="w-1/3"
          position="center-right"
          modalClassName="w-1/3 h-screen rounded-none"
          btnLabelSubmit="Kirim"
          btnLabelCancel="Batal"
        >
          <FormProvider {...methodsPenugasan}>
            <PICForm />
          </FormProvider>
        </SideModal>

        {/* Modal Detail Petugas */}
        <SideModal
          title="Detail Petugas"
          open={isOpenDetail}
          onClose={() => {
            setIsOpenDetail(false);
            setSelectedDetail(null);
          }}
          onSubmit={(data) => {
            setIsOpenDetail(false);
            onOpenEdit(selectedDetail);
          }}
          width="w-1/2"
          position="center-right"
          modalClassName="w-1/2 h-screen rounded-none"
          btnLabelSubmit="Pindah Tugas"
          btnLabelCancel="Tutup"
        >
          <PICDetail selectedUser={selectedDetail} />
        </SideModal>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {/* Modal */}
      {renderModal()}

      {/* Table */}
      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <Table
              bordered
              stripped
              layout="fixed"
              className="mb-4"
              columns={tableColumns}
              dataSource={assignmentData?.result}
              isLoading={isLoading}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              pagination={{
                page: assignmentData?.paginator?.page ?? 1,
                limit: assignmentData?.paginator?.limit ?? 10,
                itemCount: assignmentData?.paginator?.total ?? 0,
                previous_pages: null,
                next_pages: null,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PICTable;
