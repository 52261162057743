import config from "app/config";
import axiosInstance from "app/interceptors";

export const getEvaluationPlan = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation-plan`,
    { params, signal }
  );
};

export const getListTimeline = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation-plan/list/timeline`,
    { params, signal }
  );
}

export const addEvaluationPlan = (payload) => {
  const { data } = axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation-plan/add-plan`,
    payload
  );
  return data;
};

export const removeEvaluationPlan = (payload) => {
  const { data } = axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/evaluation-plan/remove-plan`,
    payload
  );
  return data;
};
