import { CustomSelect, Label, Spinner } from 'components';
import { CustomDatepickerForm, FilePickerForm, InputForm } from 'components/HookForm';
import { useWorkingAreaQuery } from 'hooks/useMasterData';
import React from 'react'
import { useFormContext } from 'react-hook-form';

const PICForm = () => {
  const { setValue } = useFormContext();

  const { data: workingAreaOpt, isLoading } = useWorkingAreaQuery([
    "working-area-opt",
  ]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label>Nama Petugas</Label>
          <InputForm name={`name`} className="w-full" disabled />
        </div>
        <div>
          <Label>NIK</Label>
          <InputForm name={`nik`} className="w-full" disabled />
        </div>
      </div>
      <div>
        <Label>No. Surat Penugasan</Label>
        <InputForm
          name={`assignment_number`}
          className="w-full"
          placeholder="1234/1234/1234"
        />
      </div>
      <div className="relative">
        <Label>Wilayah Kerja</Label>
        {isLoading ? (
          <Spinner />
        ) : (
          <CustomSelect
            name="area"
            placeholder="Pilih Area"
            optionsData={workingAreaOpt?.data?.result.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            className="w-full"
            isMulti
          />
        )}
      </div>
      <div>
        <Label>Mulai dari</Label>
        <CustomDatepickerForm
          name={"start_date"}
          placeholder={"Pilih Periode"}
          dateFormat="yyyy-MM-dd"
          className="w-full"
          onClear={() => setValue("start_date", null)}
        />
      </div>
      <FilePickerForm name="document" label="Dokumen Bukti Pendukung" />
    </div>
  );
}

export default PICForm