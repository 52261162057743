import { Pagination } from "components";
import NotificationItem from "components/Notification/NotificationItem";

const NotificationTable = ({ dataSource, isLoading, ...paginationProps }) => {
  return (
    <div className="flex w-full p-0">
      <div className="flex w-full card bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-0">
          {isLoading
            ? "loading"
            : dataSource.map((data) => {
                return (
                  <div className="w-full">
                    <NotificationItem {...data} />
                  </div>
                );
              })}
          <Pagination {...paginationProps} />
        </div>
      </div>
    </div>
  );
};

export default NotificationTable;
