import config from "app/config";
import axiosInstance from "app/interceptors";

export const getAssignment = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/assignment`,
    { params, signal }
  );
};

export const getAssignmentDetail = async (id) => {
  const { data } = await axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/assignment/${id}`
  );
  return data;
};

export const getAssignmentByUser = (id, params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/assignment/${id}/user`,
    { params, signal }
  );
};

export const putAssignmentByUser = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/assignment/${params.id}/user`,
    params.payload
  );
};
