import { Breadcrumbs, TitleText } from "components";
import { map } from "lodash";
import { useState } from "react";
import "../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import PlanningCalendar from "./components/Calendar";
import PlanningFilter from "./components/Filter";
import RealizationTable from "./components/Table/realization";
import RegisteredPlanningTable from "./components/Table/registered-planning";
import UnregisteredPlanningTable from "./components/Table/unregistered-planning";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";

const EvaluatePlanning = () => {
  const [active, setActive] = useState(0);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [date, setDate] = useState();
  const [dateSelected, setDateSelected] = useState(false);

  const filterMethods = useForm();

  const links = [
    {
      label: "Evaluasi",
      path: "#",
    },
    {
      label: "Daftar Perencanaan",
    },
  ];

  const tabs = [
    {
      label: "Perencanaan",
      count: 20,
    },
    {
      label: "Realisasi",
      count: 80,
    },
  ];

  const handleClickEvent = (event) => {
    if (dateSelected === event.id) {
      setDateSelected(false); // Deselect if the same ID is clicked
      setDate(null);
    } else {
      setDate(moment(event.start).format("YYYY-MM-DD"));
      setDateSelected(event.id);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <TitleText className="flex-1">Daftar Perencanaan</TitleText>

      <PlanningCalendar
        onEventClick={handleClickEvent}
        isSelected={dateSelected}
      />

      {/* Filter */}
      <FormProvider {...filterMethods}>
        <PlanningFilter
          isFilterActive={isFilterActive}
          setIsFilterActive={setIsFilterActive}
          date={date}
        />
      </FormProvider>

      {/* Tabs */}
      <div className="tabs">
        {map(tabs, (tab, key) => (
          <button
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
              key === active
                ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                : "border-b"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Table */}
      {active === 0 && (
        <div className="flex w-full gap-6">
          <UnregisteredPlanningTable date={date} />
          {dateSelected && <RegisteredPlanningTable date={date} />}
        </div>
      )}
      {active === 1 && <RealizationTable />}

      {/* Empty Table */}
      {/* <div className="flex flex-col justify-center items-center py-10 px-8 gap-1">
        <HiSearchCircle size={30} color="#00BD52"/>
        <p className="font-bold">Belum Ada Data</p>
        <p className="text-sm">Harap pilih tanggal terlebih dahulu pada kolom linimasa diatas</p>
      </div> */}
    </div>
  );
};

export default EvaluatePlanning;
