import { Input, Label, Table } from "components";
import {
  useAssignmentFDBByUser,
  useAssignmentFDBDetail,
} from "hooks/useAssignmentFDB";
import moment from "moment";
import { useState } from "react";

const PICDetail = ({ selectedUser }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: detailData } = useAssignmentFDBDetail(
    ["assignment-detail", selectedUser?.user_id],
    { enabled: !!selectedUser }
  );

  const { data: userAssignment, isLoading: isLoadingUserAssignment } = useAssignmentFDBByUser(selectedUser?.user_id, [
    "assignment-by-user",
    { page, limit },
  ]);

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "assignment_number",
      title: "No Penugasan",
      dataIndex: "assignment_number",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
    {
      id: "start_date",
      title: "Tanggal",
      dataIndex: "start_date",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ? moment(value).format("DD/MM/YYYY") : "-"}
        </div>
      ),
    },
    {
      id: "document",
      title: "Dokumen",
      dataIndex: "document",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value ?? "-"}
        </div>
      ),
    },
  ];

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center w-full">
        <img
          src="https://icon-library.com/images/generic-user-icon/generic-user-icon-13.jpg"
          className="rounded-full w-40 h-40"
          alt="profil-icon"
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label>Nama Petugas</Label>
          <Input
            name={`name`}
            value={detailData?.name}
            className="w-full"
            disabled
          />
        </div>
        <div>
          <Label>NIK</Label>
          <Input
            name={`nik`}
            value={detailData?.nik}
            className="w-full"
            disabled
          />
        </div>
        <div>
          <Label>Nomor Telepon</Label>
          <Input
            name={`phone_number`}
            value={detailData?.phone_number}
            className="w-full"
            disabled
          />
        </div>
        <div>
          <Label>Bekerja Sejak</Label>
          <Input
            name={`since_work`}
            value={
              detailData?.working_since
                ? moment(detailData?.working_since).format("DD/MM/YYYY")
                : ""
            }
            className="w-full"
            disabled
          />
        </div>
      </div>
      <hr />
      <Table
        bordered
        stripped
        layout="fixed"
        columns={tableColumns}
        dataSource={userAssignment?.result}
        isLoading={isLoadingUserAssignment}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        hidePagination
        // pagination={{
        //   page,
        //   limit,
        //   itemCount: userAssignment?.paginator?.total ?? 0,
        //   previous_pages: null,
        //   next_pages: null,
        // }}
      />
    </div>
  );
};

export default PICDetail;
