import React from "react";

const DeptorProfile = ({
  name = "",
  nik = "",
  nap = "",
  deptorGroup = "",
  address = "",
  long = "",
  lat = "",
  pic = "",
  korwil = "",
  bankName = "",
  expense = "",
  job = "",
  accountNumber = "",
  expenseSource = "",
  income = "",
}) => {
  return (
    <div className="flex flex-col lg:flex-row space-y-8 lg:space-x-8 lg:space-y-8">
      <img
        src="https://www.pngarts.com/files/5/User-Avatar-PNG-Picture.png"
        alt="people"
        className="w-52 h-52"
      />
      <div className="flex-1 space-y-8">
        <div className="flex justify-between flex-1">
          <div className="flex flex-col flex-1 space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
              <div className="text-sm font-[700]">Profil Debitur :</div>
              <div className="text-sm font-[400]">{name}</div>
              <div className="text-sm font-[700]">NIK :</div>
              <div className="text-sm font-[400]">{nik}</div>
              <div className="text-sm font-[700]">NAP :</div>
              <div className="text-sm font-[400]">{nap}</div>
              <div className="text-sm font-[700]">Kelompok Debitur :</div>
              <div className="text-sm font-[400]">{deptorGroup}</div>
            </div>
          </div>
          <div className="flex flex-col flex-1 space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
              <div className="text-sm font-[700]">Alamat :</div>
              <div className="text-sm font-[400]">{address}</div>
              <div className="text-sm font-[700]">Longitude :</div>
              <div className="text-sm font-[400]">{long}</div>
              <div className="text-sm font-[700]">Latitude :</div>
              <div className="text-sm font-[400]">{lat}</div>
            </div>
          </div>
        </div>
        <div className="flex justify-between flex-1">
          <div className="flex flex-col flex-1 space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
              <div className="text-sm font-[700]">PIC :</div>
              <div className="text-sm font-[400]">{pic}</div>
              <div className="text-sm font-[700]">Korwil :</div>
              <div className="text-sm font-[400]">{korwil}</div>
            </div>
          </div>
          <div className="flex flex-col flex-1 space-y-4">&nbsp;</div>
        </div>
        <div className="flex justify-between flex-1">
          <div className="flex flex-col flex-1 space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
              <div className="text-sm font-[700]">Bank :</div>
              <div className="text-sm font-[400]">{bankName}</div>
              <div className="text-sm font-[700]">Pengeluaran /bl :</div>
              <div className="text-sm font-[400]">{expense}</div>
              <div className="text-sm font-[700]">Pekerjaan :</div>
              <div className="text-sm font-[400]">{job}</div>
            </div>
          </div>
          <div className="flex flex-col flex-1 space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
              <div className="text-sm font-[700]">Nomor Rekening :</div>
              <div className="text-sm font-[400]">{accountNumber}</div>
              <div className="text-sm font-[700]">Sumber pengeluaran :</div>
              <div className="text-sm font-[400]">{expenseSource}</div>
              <div className="text-sm font-[700]">Pendapatan /bln :</div>
              <div className="text-sm font-[400]">{income}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeptorProfile;
