import { useQuery } from "react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { map, debounce } from "lodash";

const useGetListTable = (name, apiCall, additionalPayload) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyword, setKeyword] = useState(undefined);
  const [order_direction, setOrderDirection] = useState("asc");
  const [order_by, setOrderBy] = useState("");
  const query = useQuery({
    queryKey: [
      `get-list-${name}`,
      {
        page,
        limit,
        search: keyword,
        ...additionalPayload,
        order_direction,
        order_by,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await apiCall(
        { ...params, order_direction, order_by },
        signal
      );
      return response.data;
    },
  });

  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
  };

  const onSearch = debounce(
    useCallback(
      (event) =>
        setKeyword(() => {
          if (event?.target?.value) return event.target.value;
          return undefined;
        }),
      []
    ),
    1000
  );

  const { refetch, ...queryResults } = query;

  return {
    ...queryResults,
    refetch,
    page,
    limit,
    keyword,
    order_direction,
    order_by,
    setOrderBy,
    setOrderDirection,
    setPage,
    setLimit,
    setKeyword,
    onChangePage,
    onSearch,
    onChangeRowsPerPage,
  };
};

export default useGetListTable;
