import { Button, CollapsingCard, Drawer, Label, Table } from "components";
import { useMemo, useState } from "react";
import { HiOutlineEye } from "react-icons/hi";
import AbilityPay from "./abilityPay";
import ActivityDocument from "./activityDocument";
import AgreementTerms from "./agreementTerms";
import BusinessProspect from "./businessProspect";
import FinancedAssets from "./financedAssets";
import GoodIntention from "./goodIntention";

const Monev = () => {
  const [detailMonev, setDetailMonev] = useState(false);

  const data = useMemo(() => {
    return [
      {
        final_assesment: "Baik",
        executor_evaluation: "Reyhan Fikran",
        executor_date: "20 Februari 2023",
        date_reviewed: "20 Februari 2023",
      },
      {
        final_assesment: "Baik",
        executor_evaluation: "Reyhan Fikran",
        executor_date: "20 Februari 2023",
        date_reviewed: "20 Februari 2023",
      },
      {
        final_assesment: "Baik",
        executor_evaluation: "Reyhan Fikran",
        executor_date: "20 Februari 2023",
        date_reviewed: "20 Februari 2023",
      },
      {
        final_assesment: "Baik",
        executor_evaluation: "Reyhan Fikran",
        executor_date: "20 Februari 2023",
        date_reviewed: "20 Februari 2023",
      },
      {
        final_assesment: "Baik",
        executor_evaluation: "Reyhan Fikran",
        executor_date: "20 Februari 2023",
        date_reviewed: "20 Februari 2023",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 5,
      previous_pages: null,
      next_pages: null,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "final_assesment",
        title: "Penilaian Akhir",
        dataIndex: "final_assesment",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "executor_evaluation",
        title: "Nama Pelaksana Evaluasi",
        dataIndex: "executor_evaluation",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "executor_date",
        title: "Tanggal Pelaksanaan Evaluasi",
        dataIndex: "executor_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "date_reviewed",
        title: "Tanggal Telah Direview Koordinator Petugas Lapangan",
        dataIndex: "date_reviewed",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        width: 160,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        // columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="space-x-3 flex items-center w-full">
            <HiOutlineEye
              className="text-gray-600 cursor-pointer flex-1"
              size={20}
              onClick={() => setDetailMonev(true)}
            />
          </div>
        ),
      },
    ];
  }, []);

  const renderModal = () => {
    return (
      <Drawer
        title="Detail Evaluasi - Dokumentasi Kegiatan"
        onClose={setDetailMonev}
        open={detailMonev}
      >
        <div className="flex flex-col gap-4">
          <CollapsingCard title="Dokumentasi Kegiatan">
            <ActivityDocument />
          </CollapsingCard>
          <CollapsingCard title="A. Kepatuhan Terhadap Ketentuan Perjanjian">
            <AgreementTerms />
          </CollapsingCard>
          <CollapsingCard title="B. Prospek Usaha Yang Dibiayai Dari FDB">
            <BusinessProspect />
          </CollapsingCard>
          <CollapsingCard title="C. Kondisi Tanaman Atau Aset Yang Dibiayai">
            <FinancedAssets />
          </CollapsingCard>
          <CollapsingCard title="D. I'tikad Baik">
            <GoodIntention />
          </CollapsingCard>
          <CollapsingCard title="E. Kemampuan Membayar">
            <AbilityPay />
          </CollapsingCard>
        </div>
      </Drawer>
    );
  };

  return (
    <>
      {renderModal()}
      <div className="flex flex-col gap-4">
        {/* Header */}
        <div className="flex">
          <div className="flex flex-col px-6 flex-1 gap-2">
            <p className="font-bold text-sm">Tipe Kegiatan:</p>
            <p className="text-lg">Tunda Tebang</p>
          </div>
          <div className="flex flex-1 items-end gap-4">
            <div className="flex flex-col flex-1 gap-2">
              <Label>Periode Evaluasi</Label>
              <input type="date" className="py-3 w-full rounded-md" />
            </div>
            <Button className="flex flex-1 bg-primary-600 border-primary-600 hover:border-primary-700 hover:bg-primary-700">
              Export & Download Dokumen
            </Button>
          </div>
        </div>

        {/* Table */}
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
          <div className="card-body p-3">
            <div className="space-y-4">
              <Table
                bordered
                stripped
                layout="fixed"
                className="mb-4"
                columns={tableColumns}
                dataSource={data}
                // isLoading={isLoading}
                // onChangePage={onChangePage}
                // onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={paginator}
              />
            </div>
          </div>
        </div>

        {/* Keterangan */}
        <p className="text-[#344054] text-sm w-[70%]">
          *Baik (semua indikator baik), Kurang Baik (1 Indikator non-baik),
          Tidak Baik (&gt;1 Indikator non-baik). Indikator A harus baik, jika
          non-baik penilaian Akhir mengikuti hasil penilaian indikator A
        </p>
      </div>
    </>
  );
};

export default Monev;
