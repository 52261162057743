/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  defer,
  Navigate,
  useOutletContext,
} from "react-router-dom";
import { map } from "lodash";

import route from "./route";
import My404Component from "pages/404";
import My403Component from "pages/403";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import { AuthLayout, BasicLayout } from "components/Layout";
import { AuthConsumer } from "providers/authProvider";
import { useSelector } from "react-redux";

import { USER } from "services";

const ProtectedRoute = ({ children, routeKey, type = "canView" }) => {
  const { userAccessRights } = useOutletContext();

  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          if (userAccessRights?.[routeKey]?.[type] || routeKey === "home") {
          return (
            <BasicLayout>
              {React.cloneElement(children, {
                accessRight: userAccessRights?.[routeKey],
              })}
            </BasicLayout>
          );
          } else return <Navigate to="/unauthorized" replace />;
        } else {
          return signinRedirect();
        }
      }}
    </AuthConsumer>
  );
};

const Routes = () => {
  const { user } = useSelector(({ auth }) => auth);

  const RouteElements = useMemo(() => {
    return createBrowserRouter([
      {
        element: <CALLBACK />,
        path: "/signin-oidc",
      },
      {
        element: <LOGOUT />,
        path: "/logout",
      },
      {
        element: <LOGOUT_CALLBACK />,
        path: "/signout-oidc",
      },
      {
        element: <SILENT_RENEW />,
        path: "/silentrenew",
      },
      {
        element: <AuthLayout />,
        loader: async ({ signal }) => {
          try {
            if (user?.UserId) {
              const { data: userDetail } = await USER.getUserDetail(
                user?.UserId,
                signal
              );
              if (
                userDetail?.userAccess &&
                Array.isArray(userDetail?.userAccess)
              ) {
                const index = userDetail?.userAccess?.findIndex(
                  (x) => x.moduleName?.toLowerCase() === "monev"
                );
                if (index > -1) {
                  const userAccess =
                    userDetail?.userAccess?.[index]?.menuModules;
                  const userAccessRights = userAccess.reduce((obj, item) => {
                    return Object.assign(obj, {
                      [item.key]: { ...item },
                    });
                  }, {});
                  return defer({
                    userAccessRights,
                  });
                }
              }
              return defer({
                userAccessRights: null,
              });
            }
            return defer({
              userAccessRights: null,
            });
          } catch (error) {
            console.log("Error while getting user access rights ", error);
            return defer({
              userAccessRights: null,
            });
          }
        },
        children: map(route, (val, key) => {
          const Component = val.element;
          return {
            key: key,
            path: val.path,
            element: (
              <ProtectedRoute routeKey={val.key} type={val.type}>
                <Component />
              </ProtectedRoute>
            ),
          };
        }),
      },
      {
        element: <My404Component />,
        path: "*",
      },
      { element: <My403Component />, path: "/unauthorized" },
    ]);
  }, [user]);
  return <RouterProvider router={RouteElements} />;
};

export default Routes;
