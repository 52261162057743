import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  oidc_user: null,
};

export const oidcSlice = createSlice({
  name: "oidc",
  initialState,
  reducers: {
    addOIDCUser: (state, action) => {
      state.oidc_user = action.payload;
    },
    removeOIDCUser: (state) => {
      state.oidc_user = null;
    },
  },
});

export const { addOIDCUser, removeOIDCUser } = oidcSlice.actions;

export default oidcSlice.reducer;
