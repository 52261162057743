import React from "react";

const CategoryBadge = ({ data }) => {
  let defaultWrapperClass = "bg-gray-50 text-gray-700";

  switch (data.id) {
    case 1:
      defaultWrapperClass = "bg-bluelight-50 text-bluelight-700";
      break;
    case 2:
      defaultWrapperClass = "bg-indigo-50 text-indigo-700";
      break;
    default:
      defaultWrapperClass = "bg-gray-50 text-gray-700";
      break;
  }
  return (
    <div
      className={`badge border-transparent p-2 text-xs ${defaultWrapperClass}`}
    >
      {data?.name ?? "-"}
    </div>
  );
};

export default CategoryBadge;
