import axios from "axios";
import { ConfirmationModal, SideModal } from "components/Modal";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import IndicatorForm from "./Form";
import { INDICATOR } from "services";
import { enqueueSnackbar } from "notistack";

const {
  Breadcrumbs,
  TitleText,
  Button,
  Table,
  Spinner,
} = require("components");

const Indicator = () => {
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteConf, setOpenDeleteConf] = useState(false);

  const {
    isLoading,
    data: indicatorData,
    refetch,
  } = useQuery({
    queryKey: [
      "indicator",
      {
        page,
        limit,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await INDICATOR.getIndicatorList({ ...params }, signal);
      return response.data;
    },
  });

  const storeIndicator = useMutation((payload) => {
    !!selected
      ? INDICATOR.editIndicator(payload)
      : INDICATOR.createNewIndicator(payload);
  });

  const removeIndicator = useMutation((payload) =>
    INDICATOR.deleteIndicator(payload)
  );

  const methods = useForm({ defaultValues: { name: "" } });

  const links = [
    {
      label: "Evaluasi",
      path: "#",
    },
    {
      label: "Indikator",
    },
  ];

  useEffect(() => {
    if (!openModal) {
      setSelected(null);
    }
    return;
  }, [openModal]);

  const onChangePage = (page) => {
    setPage(page);
    refetch();
  };

  const onChangeRowsPerPage = (limit) => {
    setLimit(limit);
    refetch();
  };

  const onTriggerEdit = (data) => {
    setOpenModal(true);
    setSelected(data);
    methods.reset({
      name: data?.name ?? "",
      hit_service: data?.hit_service ?? "",
      directorat_id: data?.directorat_id ?? "",
    });
  };

  const onTriggerDelete = (data) => {
    setOpenDeleteConf(true);
    setSelected(data);
  };

  const onDeleteData = () => {
    removeIndicator.mutate(selected?.id, {
      onSuccess: () => {
        refetch();
        setOpenDeleteConf(false);
        setSelected(null);
        enqueueSnackbar("Berhasil menghapus data", { variant: "success" });
      },
      onError: (error, variables) => {
        console.log(error, variables);
      },
    });
  };

  const onSubmit = (data) => {
    const obj = { ...data };

    if (obj.hasOwnProperty("directorat_id")) {
      obj.directorat_id = obj.directorat_id.value;
    }

    if (obj.hasOwnProperty("hit_service")) {
      if (obj.hit_service !== true) {
        obj.directorat_id = "";
      }
    }

    let params;
    if (!!selected) {
      params = {
        id: selected?.id,
        payload: {
          ...obj,
        },
      };
    } else {
      params = {
        ...obj,
      };
    }
    storeIndicator.mutate(params, {
      onSuccess: (data, variables) => {
        setOpenModal(false);
        methods.reset({ name: "" });
        setSelected(null);
        enqueueSnackbar({
          message: `Berhasil ${!!selected ? "mengubah" : "menambahkan"} data`,
          variant: "success",
        });
        setTimeout(() => {
          refetch();
        }, 150);
      },
      onError: (error, variables) => {
        //
      },
    });
  };

  const tableColumns = [
    {
      id: "no",
      title: "No",
      dataIndex: "no",
      className: "overflow-hidden",
      render: (value, data, index) => {
        const no =
          parseInt(indicatorData?.paginator?.page) *
            parseInt(indicatorData?.paginator?.limit) -
          parseInt(indicatorData?.paginator?.limit) +
          index +
          1;
        return (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {no}
          </div>
        );
      },
    },
    {
      id: "name",
      title: "List Indikator",
      dataIndex: "name",
      width: 540,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      render: (value) => (
        <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
          {value}
        </div>
      ),
    },
    {
      id: "actions",
      title: "Aksi",
      dataIndex: "actions",
      width: 160,
      className: "overflow-hidden text-ellipsis",
      sortable: true,
      // columnClassName: "text-center",
      columnClassName: "w-40 text-center",
      fixed: "right",
      render: (value, data, index) => (
        <div className="space-x-3 flex items-center w-full">
          <BiTrash
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onTriggerDelete(data)}
          />
          <BiPencil
            className="text-gray-600 cursor-pointer flex-1"
            size={20}
            onClick={() => onTriggerEdit(data)}
          />
        </div>
      ),
    },
  ];

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 10,
      total: 5,
      previous_pages: null,
      next_pages: null,
    };
  });

  return (
    <>
      <SideModal
        title={`${!!selected ? "Perbarui" : "Tambah"} List Indikator`}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={methods.handleSubmit(onSubmit)}
        width="w-1/3"
        position="center-right"
        modalClassName="!w-1/3 h-screen rounded-none"
        btnLabelSubmit={`${!!selected ? "Perbarui" : "Tambah"} Indikator`}
      >
        <FormProvider {...methods}>
          <IndicatorForm />
        </FormProvider>
      </SideModal>
      <ConfirmationModal
        open={openDeleteConf}
        onClose={setOpenDeleteConf}
        onSubmit={onDeleteData}
      />
      <div className="flex flex-col gap-4">
        <Breadcrumbs items={links} />
        <div className="md:flex block items-center my-3 justify-between">
          <TitleText className="flex-1">List Indikator</TitleText>
          <Button
            className="bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            size="md"
            startIcon={<AiOutlinePlus />}
            onClick={() => {
              methods.reset({
                name: "",
                hit_service: "",
                directorat_id: "",
              });
              setOpenModal(true);
            }}
          >
            Tambah List Indikator
          </Button>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-3">
              <div className="space-y-4">
                <Table
                  bordered
                  stripped
                  layout="fixed"
                  className="mb-4"
                  columns={tableColumns}
                  dataSource={indicatorData?.result}
                  isLoading={isLoading}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  initialRowPerPage={limit}
                  initialPage={page}
                  pagination={indicatorData?.paginator}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Indicator;
