import { Table } from "components";
import React, { useMemo, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useQuery } from "react-query";
import { ASSIGNMENT } from "services";

const PLTable = ({ keyword, filter_region }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { isLoading, data: assignmentData } = useQuery({
    queryKey: [
      "assignment",
      {
        page,
        limit,
        role: "PL",
        search: keyword,
        filter_region: filter_region,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await ASSIGNMENT.getAssignment({ ...params }, signal);
      return response.data;
    },
  });

  const result = useMemo(() => {
    return [
      {
        nik: "1234 1234 1234",
        pl_name: "Reyhan Fikran",
        region: "Kota Bandung",
        join_date: "30/12/2022",
      },
      {
        nik: "1234 1234 1234",
        pl_name: "Reyhan Fikran",
        region: "Kota Bandung",
        join_date: "30/12/2022",
      },
      {
        nik: "1234 1234 1234",
        pl_name: "Reyhan Fikran",
        region: "Kota Bandung",
        join_date: "30/12/2022",
      },
      {
        nik: "1234 1234 1234",
        pl_name: "Reyhan Fikran",
        region: "Kota Bandung",
        join_date: "30/12/2022",
      },
      {
        nik: "1234 1234 1234",
        pl_name: "Reyhan Fikran",
        region: "Kota Bandung",
        join_date: "30/12/2022",
      },
    ];
  });

  const paginator = useMemo(() => {
    return {
      page: 1,
      limit: 5,
    };
  });

  const tableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        className: "overflow-hidden",
        render: (value, data, index) => {
          const no =
            parseInt(paginator?.page) * parseInt(paginator?.limit) -
            parseInt(paginator?.limit) +
            index +
            1;
          return (
            <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
              {no}
            </div>
          );
        },
      },
      {
        id: "nik",
        title: "NIK",
        dataIndex: "nik",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "pl_name",
        title: "Nama PL",
        dataIndex: "name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "region",
        title: "Wilayah Kerja",
        dataIndex: "area",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "-"}
          </div>
        ),
      },
      {
        id: "join_date",
        title: "Tanggal Bergabung",
        dataIndex: "working_since",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {value ?? "-"}
          </div>
        ),
      },
    ];
  }, []);
  return (
    <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
      <div className="card-body p-3">
        <div className="space-y-4">
          <Table
            bordered
            stripped
            layout="fixed"
            className="mb-4"
            columns={tableColumns}
            dataSource={assignmentData?.result}
            isLoading={isLoading}
            // onChangePage={onChangePage}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={{
              page: 1,
              limit: 10,
              total: 5,
              previous_pages: null,
              next_pages: null,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PLTable;
