import { Button, CollapsingCard } from "components";
import { map } from "lodash";
import React, { useMemo, useState } from "react";
import AgreementTerms from "./agreementTerms";
import BusinessProspect from "./businessProspect";
import FinancedAssets from "./financedAssets";
import GoodIntention from "./goodIntention";
import AbilityPay from "./abilityPay";

const PerformanceEvaluation = () => {
  const [active, setActive] = useState(0);

  const tabs = useMemo(() => {
    return ["Januari", "Februari", "Maret"];
  }, []);

  return (
    <div>
      <div className="flex items-center space-x-8 mb-8">
        <div className="text-sm font-[700]">Tipe Kegiatan :</div>
        <div className="text-sm font-[400]">Tunda Tebang</div>
      </div>
      <div className="tabs mb-4">
        {map(tabs, (tab, key) => (
          <a
            onClick={() => setActive(key)}
            className={`flex-1 tab tab-bordered font-medium hover:text-primary-700 ${
              key === active
                ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                : "border-b"
            }`}
          >
            {tab}
          </a>
        ))}
      </div>
      <div className="space-y-4">
        <div className="flex">
          <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">Penilaian Akhir* :</div>
            <div className="text-sm font-[400]">Kurang baik</div>
            <div className="text-sm font-[700]">Nama Pelaksana Evaluasi :</div>
            <div className="text-sm font-[400]">{"-"}</div>
          </div>
          <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">&nbsp;</div>
            <div className="text-sm font-[400]">&nbsp;</div>
            <div className="text-sm font-[700]">
              Tgl/Hari Pelaksanaan Evaluasi :
            </div>
            <div className="text-sm font-[400]">XX Bulan XXXX</div>
          </div>
          <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
            <div className="text-sm font-[700]">&nbsp;</div>
            <div className="text-sm font-[400]">&nbsp;</div>
            <div className="text-sm font-[700]">
              Telah Direview Koordinator Petugas Lapangan Tanggal :
            </div>
            <div className="text-sm font-[400]">XX Bulan XXXX</div>
          </div>
        </div>
        <div className="font-[400] text-sm max-w-2xl">
          {
            "*Baik (semua indikator baik), Kurang Baik (1 Indikator non-baik), Tidak Baik (>1 Indikator non-baik). Indikator A harus baik, jika non-baik penilaian AKhir mengikuti hasil penilaian indikator A"
          }
        </div>
        <Button className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700">
          Export & Download Dokumen
        </Button>
        <CollapsingCard title="A. Kepatuhan Terhadap Ketentuan Perjanjian">
          <AgreementTerms />
        </CollapsingCard>
        <CollapsingCard title="B. Prospek Usaha Yang Dibiayai Dari FDB">
          <BusinessProspect />
        </CollapsingCard>
        <CollapsingCard title="C. Kondisi Tanaman Atau Aset Yang Dibiayai">
          <FinancedAssets />
        </CollapsingCard>
        <CollapsingCard title="D. I’tikad Baik">
          <GoodIntention />
        </CollapsingCard>
        <CollapsingCard title="E. Kemampuan Membayar">
          <AbilityPay />
        </CollapsingCard>
      </div>
    </div>
  );
};

export default PerformanceEvaluation;
