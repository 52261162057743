import { Spinner } from "components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { random } from "lodash";

const BarChart = ({ data = null, loading }) => {
  const defaultOptions = {
    chart: {
      backgroundColor: "#F9FAFB",
      type: "bar",
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ["Indikator A", "Indikator B", "Indikator C"],
      allowDecimals: false,
    },
    yAxis: {
      title: { enabled: false },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
      tickInterval: 10,
    },
    series: [
      {
        data: [
          { y: random(3, 50) },
          { y: random(3, 50) },
          { y: random(3, 50) },
        ],
        color: "#00BD52",
      },
    ],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          align: "right",
          color: "#FFFFFF",
          x: -10,
          format: "{y}" + "%",
        },
        pointPadding: 0.1,
        groupPadding: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  const options =
    data && data.length > 0
      ? {
          chart: {
            backgroundColor: "#F9FAFB",
            type: "bar",
          },
          title: {
            text: "Indicator Menu Scores",
          },
          xAxis: {
            categories: data.map((item) => item.name),
            allowDecimals: false,
          },
          yAxis: {
            min: 0,
            title: { enabled: false },
            labels: {
              formatter: function () {
                return this.value + "%";
              },
            },
            tickInterval: 10,
          },
          series: [
            {
              name: "Scores",
              data: data.map((item) => ({ y: item.scores })),
              color: "#00BD52",
            },
          ],
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: "right",
                color: "#FFFFFF",
                x: -10,
                format: "{y}%",
              },
              pointPadding: 0.1,
              groupPadding: 0,
            },
          },
          tooltip: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
        }
      : defaultOptions;

  if (loading) return <Spinner />;
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarChart;
