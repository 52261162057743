import ProgramPhysicalAndFinancial from "pages/Program/PhysicalAndFinancial";
import ProgramPhysicalAndFinancialDetail from "pages/Program/PhysicalAndFinancial/Detail";

const physicalAndFinancialRoutes = [
  {
    routeType: "public",
    path: "/program-physical-and-financial",
    element: ProgramPhysicalAndFinancial,
    exact: true,
    type: "canView",
    key: "program-physical-and-financial",
  },
  {
    routeType: "public",
    path: "/program-physical-and-financial/detail/:id",
    element: ProgramPhysicalAndFinancialDetail,
    exact: true,
    type: "canView",
    key: "program-physical-and-financial-detail",
  },
];

export default physicalAndFinancialRoutes;
