import { useMutation } from "react-query";
import { NOTIFICATION } from "services";
import { useNavigate } from "react-router-dom";

export const useMutationNotification = () => {
  const mutation = useMutation({
    mutationFn: (payload) => {
      return NOTIFICATION.putNotificationRead(payload);
    },
  });
  const navigate = useNavigate();

  const HandleReadNotification = (uuid) => {
    return mutation.mutate(
      { uuid },
      {
        onSuccess: (response) => {
          const { data } = response;
          const redirect = data?.result?.redirect;
          navigate(`/${redirect}`);
          console.log({ redirect });
        },
      }
    );
  };

  return HandleReadNotification;
};
