import React from "react";

const FinancedAssets = () => {
  return (
    <div className="space-y-4">
      <div className="font-[400] text-sm">
        {
          "Nilai Akhir Indikator: Baik (semua parameter bernilai baik), Kurang Baik (satu parameter bernilai non baik), Tidak Baik (lebih dari satu parameter non baik)"
        }
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Jumlah Tanaman* :</div>
          <div className="text-sm font-[400]">Tidak Baik</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Perawatan Tanaman :</div>
          <div className="text-sm font-[400]">Tidak Baik</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Penandaan Tanaman :</div>
          <div className="text-sm font-[400]">Tidak Baik</div>
        </div>
      </div>
      <div className="font-[400] text-sm">
        {"*Baik (>= 80%), Kurang Baik (60%-80%), TIdak Baik (<=60^)"}
      </div>
    </div>
  );
};

export default FinancedAssets;
