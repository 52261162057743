import EvaluatePaperWork from "pages/Evaluate/PaperWork"
import EvaluatePaperWorkDetail from "pages/Evaluate/PaperWork/Detail"
import EvaluatePaperWorkForm from "pages/Evaluate/PaperWork/Form"

const paperWorkRoutes = [
  {
    routeType: "public",
    path: "/evaluate-paper-work",
    element: EvaluatePaperWork,
    exact: true,
    type: "canView",
    key: "evaluate-paper-work",
  },
  {
    routeType: "public",
    path: "/evaluate-paper-work/detail",
    element: EvaluatePaperWorkDetail,
    exact: true,
    type: "canView",
    key: "evaluate-paper-work-detail",
  },
  {
    routeType: "public",
    path: "/evaluate-paper-work/add",
    element: EvaluatePaperWorkForm,
    exact: true,
    type: "canCreate",
    key: "evaluate-paper-work-add",
  },
  {
    routeType: "public",
    path: "/evaluate-paper-work/edit",
    element: EvaluatePaperWorkForm,
    exact: true,
    type: "canUpdate",
    key: "evaluate-paper-work-edit",
  },
]

export default paperWorkRoutes