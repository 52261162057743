import Investigate from "pages/Activity/Investigate";
import InvestigateDetail from "pages/Activity/Investigate/Detail";
import InvestigateForm from "pages/Activity/Investigate/Form";

const investigateRoutes = [
  {
    routeType: "public",
    path: "/on-desk",
    element: Investigate,
    exact: true,
    type: "canView",
    key: "on-desk",
  },
  {
    routeType: "public",
    path: "/on-desk/add/:id",
    element: InvestigateForm,
    exact: true,
    type: "canView",
    key: "on-desk-add",
  },
  {
    routeType: "public",
    path: "/on-desk/detail/:id",
    element: InvestigateDetail,
    exact: true,
    type: "canView",
    key: "on-desk-detail",
  },
];

export default investigateRoutes;
