import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "components";
import { InputForm } from "components/HookForm";
import moment from "moment";
import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import { IoMdArrowBack } from "react-icons/io";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router";
import { INVESTIGATE } from "services";

const InvestigateDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: [`investigate-detail`, params.id],
    queryFn: async () => {
      const resp = await INVESTIGATE.getInvestigateDetail(params.id);
      return resp.data;
    },
  });

  console.log({ data });
  const links = [
    {
      label: "Laporan",
      path: "#",
    },
    {
      label: "Investigasi",
      path: "/investigate",
    },
    {
      label: "Laporan Dalam Proses",
    },
  ];

  const methods = useForm({});

  const { control } = methods;

  useEffect(() => {
    if (data) {
      methods.reset({
        ...data.result,
        date: data?.result.date
          ? moment(data?.result.date).format("DD/MM/YYYY")
          : "",
      });
    }
  }, [data]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "investigation-result",
  });

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <FormProvider {...methods}>
        {/* Informasi Penyidik */}
        <Card>
          <CardHeader>Informasi Penyidik</CardHeader>
          <CardBody>
            <div className="flex items-center gap-4">
              <div className="flex flex-col flex-1 gap-1">
                <Label required>Nama Petugas </Label>
                <InputForm
                  name="name"
                  className="w-full"
                  placeholder="Nama Petugas"
                  disabled
                  value={data?.result.name}
                />
              </div>

              <div className="flex flex-col flex-1 gap-1">
                <Label required>Tanggal Investigasi</Label>
                <InputForm name="date" className="w-full" disabled />
              </div>
            </div>
          </CardBody>
        </Card>

        {/* Informasi Investigasi */}
        <Card>
          <CardHeader>Informasi Investigasi</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Nama Kegiatan</Label>
                  <InputForm
                    name="program"
                    className="w-full"
                    placeholder="Nama Kegiatan"
                    disabled
                  />
                </div>

                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Tanggal Pelaksanaan</Label>
                  <InputForm
                    type="date"
                    name="implementation_date"
                    className="w-full"
                    disabled
                  />
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Biaya</Label>
                  <InputForm
                    name="cost"
                    className="w-full"
                    placeholder="Biaya"
                    disabled
                  />
                </div>

                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Rencana Kegiatan</Label>
                  <InputForm name="activityPlan" className="w-full" disabled />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <Label>Keterangan</Label>
                <InputForm
                  name="description"
                  className="w-full"
                  placeholder="Keterangan"
                  disabled
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <div className="flex justify-between p-4 border rounded-lg">
          <Button
            onClick={() => navigate("/on-desk")}
            startIcon={<IoMdArrowBack />}
            className="bg-white text-slate-700 border-slate-200 hover:bg-slate-700 hover:border-sky-700 hover:text-white"
          >
            Kembali
          </Button>
          <Button
            startIcon={<FiSave />}
            className="bg-primary-700 text-primary-50 border-primary-700 hover:bg-primary-700/80 hover:border-primary-700/80"
          >
            Proses Investigasi
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default InvestigateDetail;
