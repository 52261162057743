import React from "react";

const AgreementTerms = ({ data }) => {
  return (
    <div className="space-y-4">
      <div className="flex">
        {data.length > 0
          ? data.map((list) => {
              return (
                <div className="flex flex-col flex-1">
                  <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2 mb-4">
                    <div className="text-sm font-[700]">
                      {list.question.question}
                    </div>
                  </div>
                  <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
                    <div className="text-sm font-[700]">
                      Hasil Penilaian PL* :
                    </div>
                    <div className="text-sm font-[400]">{list.answer}</div>
                  </div>
                  <div className="flex flex-col">
                    {list.sub_question_answer.length > 0
                      ? list.sub_question_answer.map((sub) => {
                          return (
                            <>
                              {/* <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
                              <div className="text-sm font-[700]">
                                Hasil Penilaian PL* :
                              </div>
                              <div className="text-sm font-[400]">
                                {sub.answer}
                              </div>
                            </div> */}
                              <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
                                <div className="text-sm font-[700]">
                                  {sub.question.question}
                                </div>
                                <div className="text-sm font-[400]">
                                  {sub.answer}
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default AgreementTerms;
