import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
} from "components";
import { FilePickerForm, InputForm, RadioGroupForm } from "components/HookForm";
import { useUploadFile } from "hooks/externalService";
import {
  useInvestigateDetail,
  useInvestigateResult,
} from "hooks/useInvestigate";
import DocIcon from "../../../../assets/doc-green.svg";
import { map } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FiPlus, FiSave, FiTrash } from "react-icons/fi";
import { IoMdArrowBack, IoMdInformationCircleOutline } from "react-icons/io";
import { useParams, useNavigate } from "react-router";
import { FormModal } from "components/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { enqueueSnackbar } from "notistack";

const InvestigateForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useInvestigateDetail(params);

  const [attachment, setAttachment] = useState([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [imageActive, setImageActive] = useState(null);

  const mutateResult = useInvestigateResult();

  const links = [
    {
      label: "Laporan",
      path: "#",
    },
    {
      label: "Investigasi",
      path: "/investigate",
    },
    {
      label: "Laporan Dalam Proses",
    },
  ];

  const schema = yup.object({
    anomali_indicator: yup.string().required("Tidak boleh kosong"),
    document: yup
      .mixed()
      .test("required", "Tidak boleh kosong", function (value) {
        return !!value;
      })
      .test({
        message: "File tidak boleh lebih dari 10MB",
        test: (value) => {
          return value?.[0]?.size <= 10 * 1024 * 1024; // 10MB in bytes
        },
      })
      .test({
        message: "Format file hanya boleh PDF",
        test: (value) => {
          const supportedFormats = ["pdf"];
          return supportedFormats.includes(
            value?.[0]?.name.split(".").pop().toLowerCase()
          );
        },
      }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      anomali_indicator: "",
      investigations: [],
    },
  });

  const { control } = methods;

  useEffect(() => {
    if (data) {
      methods.reset({
        ...data.result,
        date: data?.result.date
          ? moment(data?.result.date).format("DD/MM/YYYY")
          : "",
      });
      if (Object.keys(data.result.results).length > 0) {
        methods.reset({
          anomali_indicator: data.result.results.hasAnomalyIndication ? 1 : 0,
          investigations: data.result.results.evidences.map((e) => {
            return {
              result: e,
            };
          }),
        });
        setAttachment(data.result.results.results);
      }
    }
  }, [data]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "investigations",
  });

  const uploadFile = useUploadFile();

  const onSubmit = async (data) => {
    const formDataAssignment = new FormData();

    formDataAssignment.append("file", data?.document[0]);
    const uploadAttachment = uploadFile.mutateAsync(formDataAssignment);

    const uploadData = await Promise.all([uploadAttachment]);

    const payload = {
      results: uploadData[0].data.url ? [uploadData[0].data.url] : [],
      hasAnomalyIndication: Boolean(data.anomali_indicator),
      evidences:
        data?.investigations?.length > 0
          ? data.investigations.map((e) => e.result)
          : [],
      id: params.id,
    };

    mutateResult.mutate(payload, {
      onSuccess: (data, variables) => {
        enqueueSnackbar({
          message: `Berhasil mengubah data`,
          variant: "success",
        });
        navigate("/on-desk");
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      {/* Informasi Penyidik */}
      <FormProvider {...methods}>
        <Card>
          <CardHeader>Informasi Penyidik</CardHeader>
          <CardBody>
            <div className="flex items-center gap-4">
              <div className="flex flex-col flex-1 gap-1">
                <Label required>Nama Petugas</Label>
                <InputForm
                  name="name"
                  className="w-full"
                  placeholder="Nama Petugas"
                  disabled
                />
              </div>

              <div className="flex flex-col flex-1 gap-1">
                <Label required>Tanggal Investigasi</Label>
                <InputForm name="date" className="w-full" disabled />
              </div>
            </div>
          </CardBody>
        </Card>

        {/* Informasi Investigasi */}
        <Card>
          <CardHeader>Informasi Investigasi</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Nama Kegiatan</Label>
                  <InputForm
                    name="program"
                    className="w-full"
                    placeholder="Nama Kegiatan"
                    disabled
                  />
                </div>

                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Tanggal Pelaksanaan</Label>
                  <InputForm
                    type="date"
                    name="implementation_date"
                    className="w-full"
                    disabled
                  />
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Biaya</Label>
                  <InputForm
                    name="cost"
                    className="w-full"
                    placeholder="Biaya"
                    disabled
                  />
                </div>

                <div className="flex flex-col flex-1 gap-1">
                  <Label required>Rencana Kegiatan</Label>
                  <InputForm name="activityPlan" className="w-full" disabled />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <Label>Keterangan</Label>
                <InputForm
                  name="description"
                  className="w-full"
                  placeholder="Keterangan"
                  disabled
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>Hasil Desk Analyst</CardHeader>
          <CardBody>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Label>Ada Indikasi Anomali?</Label>
                <RadioGroupForm
                  items={[
                    { label: "Ya, ada indikasi anomali", value: 1 },
                    { label: "Tidak ada indikasi anomali", value: 0 },
                  ]}
                  name="anomali_indicator"
                />
              </div>

              {/* TODO: create dynamic form */}
              {map(fields, (item, idx) => (
                <div key={item.id}>
                  <InvestigationResultForm
                    fields={fields}
                    idx={idx}
                    alias={`investigations.${idx}`}
                    remove={() => remove(idx)}
                  />
                </div>
              ))}

              <div className="flex justify-end">
                <Button
                  startIcon={<FiPlus />}
                  className="bg-primary-50 text-primary-700 border-primary-50 hover:bg-primary-700 hover:border-primary-700 hover:text-primary-50"
                  onClick={() => {
                    append({
                      result: "",
                    });
                  }}
                >
                  Tambah Hasil Investigasi
                </Button>
              </div>

              <hr />

              <div className="flex flex-col gap-2">
                <Label>Bukti Lampiran</Label>
                <div className="flex items-center w-full text-xs font-medium p-2 gap-2 border rounded-lg">
                  <IoMdInformationCircleOutline size={16} />
                  <span>Bisa upload lebih dari satu</span>
                </div>

                {attachment.length > 0 ? (
                  attachment.map((data) => {
                    return (
                      <div className="border-gray-200 px-4 py-2 rounded-md border-2">
                        <div className="flex items-start gap-4">
                          <img src={DocIcon} alt="doc-icon" />
                          <div classsName="text-sm font-normal">
                            <p className="text-sm font-normal">Dokumen Bukti</p>

                            <button
                              onClick={() => {
                                setImageActive(data);
                                setOpenModal(true);
                              }}
                              target="#"
                              className="color-[##00BD52]"
                            >
                              Lihat Gambar
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <FilePickerForm name="document" label="" />
                )}
              </div>
            </div>
          </CardBody>
        </Card>

        <FormModal
          open={isOpenModal}
          onClose={setOpenModal}
          width="w-1/3"
          hasButton={false}
        >
          <img src={imageActive} class="w-full" alt="current image" />
        </FormModal>

        <div className="flex justify-between p-4 border rounded-lg">
          <Button
            onClick={() => navigate("/on-desk")}
            startIcon={<IoMdArrowBack />}
            className="bg-white text-slate-700 border-slate-200 hover:bg-slate-700 hover:border-sky-700 hover:text-white"
          >
            Kembali
          </Button>
          {data && Object.keys(data.result.results).length === 0 && (
            <Button
              startIcon={<FiSave />}
              className="bg-primary-700 text-primary-50 border-primary-700 hover:bg-primary-700/80 hover:border-primary-700/80"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Laporkan Hasil
            </Button>
          )}
        </div>
      </FormProvider>
    </div>
  );
};

const InvestigationResultForm = ({ fields, alias, remove }) => {
  return (
    <Fragment>
      <div className="flex flex-col gap-1">
        <Label required>Hasil Investigasi</Label>
        <div className="flex items-center gap-2">
          <InputForm
            name={`${alias}.result`}
            className="form-control w-full"
            placeholder="Hasil Investigasi"
          />
          {fields.length > 1 && (
            <Button
              iconOnly
              startIcon={<FiTrash size={16} />}
              size="md"
              className="bg-red-500 border-red-500 text-white hover:bg-red-600 hover:border-red-600"
              onClick={remove}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default InvestigateForm;
