import {
  Breadcrumbs,
  Button,
  CollapsingCard,
  CustomSelect,
  Label,
  TitleText,
} from "components";
import { CustomDatepickerForm } from "components/HookForm";
import { find, map } from "lodash";
import { useMemo } from "react";
import { BiPencil } from "react-icons/bi";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { WP } from "services";

const aTypeOptions = [
  { value: 1, label: "Checkbox" },
  { value: 2, label: "Radio" },
  { value: 3, label: "Dropdown" },
];

const EvaluatePaperWorkDetail = () => {
  const location = useLocation();

  const id = useMemo(() => location?.state?.id ?? null);

  const { isLoading, data: workingPaperDetail } = useQuery({
    queryKey: ["paper-work-detail", id],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await WP.getWorkingPaperDetail(params);
      return response.result;
    },
  });

  const links = [
    {
      label: "Evaluasi",
      path: "#",
    },
    {
      label: "Kertas Kerja",
      path: "/evaluate-paper-work",
    },
    {
      label: "Detail",
    },
  ];

  return (
    <div className="space-y-6">
      <Breadcrumbs items={links} />
      <div className="md:flex block items-center my-3 justify-between">
        <TitleText className="flex-1">Detail Kertas Kerja</TitleText>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full gap-4">
          <div className="flex flex-col w-1/3 gap-2">
            <Label>Nama Kegiatan</Label>
            <div>
              {workingPaperDetail?.program_name
                ? workingPaperDetail?.program_name
                : "-"}
            </div>
          </div>
          <div className="flex flex-col w-1/3 gap-2">
            <Label>Period</Label>
            <div>
              {workingPaperDetail?.period ? workingPaperDetail?.period : "-"}
            </div>
          </div>
          <div className="flex flex-col w-1/3 gap-2">
            <Label>Status</Label>
            <div>
              {workingPaperDetail?.status ? workingPaperDetail?.status : "-"}
            </div>
          </div>
          {/* <div className="flex flex-col w-1/4 gap-2">
          <Label>Tanggal Dibuat</Label>
          <CustomDatepickerForm name="created_at" placeholder="Tanggal Dibuat"  />
        </div>
        <div className="flex flex-col w-1/4 gap-2">
          <Label>Tanggal Diubah</Label>
          <CustomDatepickerForm />
        </div> */}
        </div>
      </div>
      {map(workingPaperDetail?.question_data, (question_data, key) => (
        <CollapsingCard key={key} title={question_data?.indicator_name}>
          <div className="space-y-4">
            {map(question_data?.questions, (question, key) => (
              <>
                <div
                  key={key}
                  className="border border-primary-600 p-4 rounded-lg grid grid-cols-3 gap-4"
                >
                  <div className="flex flex-col space-y-2">
                    <label className="text-sm font-medium">
                      Nama Penilaian
                    </label>
                    <label className="text-sm">
                      {question?.question ? question?.question : "-"}
                    </label>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="text-sm font-medium">
                      Jenis Pertanyaan
                    </label>
                    <label className="text-sm">
                      {question?.question_type ? question?.question_type : "-"}
                    </label>
                  </div>
                  {question?.question_type === "Multiple" && (
                    <div className="flex flex-col space-y-2">
                      <label className="text-sm font-medium">
                        Jenis Jawaban
                      </label>
                      <label className="text-sm">
                        {question?.input_type === "1"
                          ? "Checkbox"
                          : question?.input_type === "2"
                          ? "Radio"
                          : question?.input_type === "3"
                          ? "Dropdown"
                          : "-"}
                        {/* {question?.input_type
                          ? find(aTypeOptions, { value: question?.input_type })
                              ?.value
                            ? find(aTypeOptions, { value: question?.input_type })
                                ?.value
                            : "-"
                          : "-"} */}
                      </label>
                    </div>
                  )}
                  {question?.question_type === "Multiple" && (
                    <div className="col-span-3 space-y-2">
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm font-medium">
                          Nama Penilaian
                        </label>
                        {map(question?.question_answers, (answer, key) => (
                          <div
                            key={key}
                            className="flex items-center space-x-2"
                          >
                            <input type="radio" disabled />
                            <label className="text-sm">
                              {answer ? answer?.name : "-"}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Sub question */}
                  {map(question?.sub_questions, (sub, key) => (
                    <div
                      className="border p-4 rounded-lg grid grid-cols-3 gap-4 col-span-3"
                      key={key}
                    >
                      <span className="col-span-3 text-primary-700 font-medium">Sub Question</span>
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm font-medium">
                          Nama Penilaian
                        </label>
                        <label className="text-sm">
                          {sub?.question ? sub?.question : "-"}
                        </label>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label className="text-sm font-medium">
                          Jenis Pertanyaan
                        </label>
                        <label className="text-sm">
                          {sub?.question_type
                            ? sub?.question_type
                            : "-"}
                        </label>
                      </div>
                      {sub?.question_type === "Multiple" && (
                        <div className="flex flex-col space-y-2">
                          <label className="text-sm font-medium">
                            Jenis Jawaban
                          </label>
                          <label className="text-sm">
                            {sub?.input_type === "1"
                              ? "Checkbox"
                              : sub?.input_type === "2"
                              ? "Radio"
                              : sub?.input_type === "3"
                              ? "Dropdown"
                              : "-"}
                            {/* {sub?.input_type
                          ? find(aTypeOptions, { value: sub?.input_type })
                              ?.value
                            ? find(aTypeOptions, { value: sub?.input_type })
                                ?.value
                            : "-"
                          : "-"} */}
                          </label>
                        </div>
                      )}
                      {sub?.question_type === "Multiple" && (
                        <div className="col-span-3 space-y-2">
                          <div className="flex flex-col space-y-2">
                            <label className="text-sm font-medium">
                              Nama Penilaian
                            </label>
                            {map(sub?.question_answers, (answer, key) => (
                              <div
                                key={key}
                                className="flex items-center space-x-2"
                              >
                                <input type="radio" disabled />
                                <label className="text-sm">
                                  {answer ? answer?.name : "-"}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
        </CollapsingCard>
      ))}
    </div>
  );
};

export default EvaluatePaperWorkDetail;
