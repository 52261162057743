import config from "app/config";
import axiosInstance from "app/interceptors";

export const getWorkingPaperList = (params, signal) => {
  return axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper`, { params, signal });
}

export const getWorkingPaperDetail = async (id) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper/${id}`);
  return data;
}

export const createNewWorkingPaper = (payload) => {
  return axiosInstance.post(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper`, payload);
}

export const editWorkingPaper = (params) => {
  return axiosInstance.put(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper/${params?.id}`, params?.payload);
}

export const deleteWorkingPaper = (id) => {
  return axiosInstance.delete(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper/${id}`);
}

export const getWorkingPaperServiceType = async (params) => {
  const { data } = await axiosInstance.get(`${config.BASE_URL}/api/monitoring-evaluasi/v1/working-paper/list/service-type`);
  return data;
}