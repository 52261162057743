import React from "react";
import { useNavigate } from "react-router";
import NotificationPopup from "components/Notification/NotificationPopup";

const Header = () => {
  const navigate = useNavigate();

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <nav className="navbar border-b top-0 px-8" style={{ zIndex: 99 }}>
      <div className="flex-1">
        <a className="border border-gray-300 rounded-lg px-4 py-2 text-sm font-medium">
          Portal Menu E-Office
        </a>
      </div>
      <div className="flex-none gap-6">
        <NotificationPopup />
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src="https://www.pngarts.com/files/5/User-Avatar-PNG-Picture.png" />
              </div>
            </label>
          </div>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 z-50"
          >
            <li onClick={() => onLogout()}>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
