import { useQuery } from "react-query";
import { getListMasterData, getWorkingArea } from "services/master-data";
import { PROGRAM } from "services";

const useMasterData = (module) => {
  return useQuery({
    queryKey: [`master-data-${module}`, module],
    queryFn: async () => {
      const resp = await getListMasterData(module);
      return resp.data;
    },
  });
};

export const useProgramDropdownList = () => {
  const { isLoading: loadingProgram, data: programData } = useQuery({
    queryKey: [
      "program-list",
      {
        page: 1,
        limit: 1000,
      },
    ],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await PROGRAM.getProgramList({ ...params }, signal);
      return response.data;
    },
  });

  const programList =
    programData?.result?.length > 0
      ? programData?.result?.map((list) => {
          return {
            label: list.program_name,
            value: list.program_id,
          };
        })
      : [];

  return {
    programList,
    loadingProgram,
  };
};

export const useWorkingAreaQuery = ({keys, options}) => {
  return useQuery({
    queryKey: keys,
    queryFn: () => {
      return getWorkingArea();
    },
    ...options,
  });
}

export default useMasterData;
