import Monitoring from "pages/Monitoring";
import MonitoringDetail from "pages/Monitoring/Detail";

const monitoringRoutes = [
  {
    routeType: "public",
    path: "/monitoring",
    element: Monitoring,
    exact: true,
    type: "canView",
    key: "monitoring",
  },
  {
    routeType: "public",
    path: "/monitoring/detail/:id",
    element: MonitoringDetail,
    exact: true,
    type: "canView",
    key: "monitoring-detail",
  },
];

export default monitoringRoutes;
