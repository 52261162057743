import { Button, TitleText } from "components";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { FiAlertCircle } from "react-icons/fi";

const SideModal = ({
  title = "",
  open,
  onClose,
  onSubmit,
  children,
  width = "",
  btnLabelSubmit = "Setujui",
  btnLabelCancel = "Batal",
  position = "center",
  modalClassName = "",
}) => {
  const handlePosition = (pos) => {
    switch (pos) {
      case "top-left":
        return "items-start justify-start";
      case "top-center":
        return "items-start justify-center";
      case "top-right":
        return "items-start justify-end";
      case "center-left":
        return "items-center justify-start";
      case "center":
        return "items-center justify-center";
      case "center-right":
        return "items-center justify-end";
      case "bottom":
        return "items-center justify-center";
      case "bottom-left":
        return "items-end justify-start";
      case "bottom-center":
        return "items-end justify-center";
      case "bottom-right":
        return "items-end justify-end";
      default:
        return "items-center justify-center";
    }
  };
  return (
    <div>
      {open && (
        <div
          className={`flex ${handlePosition(
            position
          )} fixed inset-0 w-screen h-screen bg-black/70 z-20`}
        >
          <motion.div
            key="modal-confirmation"
            className={`bg-white rounded-xl ${modalClassName ?? ""} ${
              width ?? "w-1/2"
            }`}
            animate={{ x: 0 }}
            transition={{ delay: 0.01 }}
            initial={{ x: 1000 }}
            exit={{ x: 1000 }}
          >
            {/* <div
              key="modal-confirmation"
              className={`bg-white rounded-xl ${modalClassName ?? ""} ${
                width ?? "w-1/2"
              }`}
            > */}
            <div className="flex flex-col py-4 justify-between h-full">
              <div className="space-y-4 h-full">
                <div className="flex items-center justify-between px-8">
                  <TitleText className="">{title}</TitleText>
                  <CgClose
                    className="text-xl cursor-pointer"
                    onClick={() => onClose(false)}
                  />
                </div>
                <div className="overflow-y-scroll px-8 py-4 h-full max-h-[85vh]">
                  {children}
                </div>
              </div>
              <div className="flex items-center space-x-4 px-8">
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 flex-1"
                  onClick={() => onClose(false)}
                >
                  {btnLabelCancel ?? "Batal"}
                </Button>
                <Button
                  type="button"
                  className={`text-white ${
                    btnLabelSubmit === "Tolak"
                      ? "border-[#BC3A12] bg-[#BC3A12]"
                      : "border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                  }  flex-1`}
                  onClick={onSubmit}
                >
                  {btnLabelSubmit ?? "Setujui"}
                </Button>
              </div>
            </div>
            {/* </div> */}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default SideModal;
