import React, { useRef, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import NotificationItem from "./NotificationItem";
import { Button } from "components";

import { useQuery } from "react-query";
import { NOTIFICATION } from "services";
import { useMutationNotification } from "hooks/useNotification";

export default function NotificationPopup() {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const limit = 5;
  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const { isLoading: loading, data: notificationLists } = useQuery({
    queryKey: ["assignment", { limit }],
    queryFn: async ({ signal, queryKey }) => {
      const params = queryKey[1];
      let response = await NOTIFICATION.getNotificationList(
        { ...params },
        signal
      );
      return response.data?.result;
    },
    enabled: isOpen,
  });

  const mutation = useMutationNotification();

  const handleUpdate = (uuid) => {
    mutation.mutate(
      { uuid },
      {
        onSuccess: (response) => {
          const { data } = response;
          const redirect = data?.result?.redirect;
          console.log({ data });
          navigate(`/${redirect}`);
        },
      }
    );
  };

  return (
    <div className="dropdown dropdown-end" ref={dropdownRef}>
      <div className="flex items-center space-x-2">
        <label tabIndex="1">
          <IoMdNotificationsOutline
            className="text-gray-500 text-2xl cursor-pointer"
            onClick={handleToggle}
          />
        </label>
      </div>
      {isOpen && (
        <ul
          tabIndex="1"
          className="shadow-lg rounded-xl menu  dropdown-content bg-base-100 w-[344px] z-50"
        >
          {loading
            ? "loading"
            : notificationLists.slice(0, 5).map((data) => {
                return (
                  <li>
                    <NotificationItem {...data} onHandleRead={handleUpdate} />
                  </li>
                );
              })}
          <div className="flex justify-center py-4">
            <Link to="/notification">
              <Button outline={true}>Lihat Semua Notifikasi</Button>
            </Link>
          </div>
        </ul>
      )}
    </div>
  );
}
