import React from "react";

const AgreementTerms = () => {
  return (
    <div className="space-y-4">
      <div className="font-[400] text-sm">
        {
          "Nilai Akhir Indikator: Baik (Affirmatif dan Negative bernilai baik), Kurang Baik (salah satu bernilai nonbaik), Tidak Baik (kedua nilai non baik)"
        }
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Affirmative Covenants</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Negative Covenants</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">NIlai Akhir Indikator :</div>
          <div className="text-sm font-[400]">Baik</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Hasil Penilaian PL* :</div>
          <div className="text-sm font-[400]">Baik</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Hasil Penilaian PL* :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Kondisi Khusus :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Penggunaan dana pinjaman :</div>
          <div className="text-sm font-[400]">Selesai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Aset Tanaman :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Tanaman :</div>
          <div className="text-sm font-[400]">Selesai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Tanda Tanaman: :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Pengembalian :</div>
          <div className="text-sm font-[400]">Selesai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Lahan :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Laporan :</div>
          <div className="text-sm font-[400]">Selesai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">
            Lahan Hanya Untuk 1 Layanan :
          </div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">Tidak Mengajukan Pailit :</div>
          <div className="text-sm font-[400]">Sesuai</div>
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-y-2 lg:gap-y-4 lg:gap-x-2">
          <div className="text-sm font-[700]">&nbsp;</div>
          <div className="text-sm font-[400]">&nbsp;</div>
        </div>
      </div>
      <div className="font-[400] text-sm">
        {
          "*Masing-masing paramenter dinilai dengan cara; Baik (Semua ketentuan sesuai), Kurang BAik (jika terdapat 1-2 ketentuan tidak sesuai), Tidak Baik (lebih dari 2 ketentuan tidak baik)"
        }
      </div>
    </div>
  );
};

export default AgreementTerms;
