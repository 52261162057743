import { Button, CustomSelect, Label, TitleText } from "components";
import { CustomSelectForm, InputForm } from "components/HookForm";
import React, { Fragment } from "react";
import { BiChevronDown, BiChevronUp, BiFilterAlt } from "react-icons/bi";

const PlanningFilter = ({ isFilterActive, setIsFilterActive, date }) => {
  const provinceOpt = [
    {value: "Jawa Barat", label: "Jawa Barat"}
  ];
  
  const cityOpt = [
    {value: "Kabupaten Bandung Barat", label: "Kabupaten Bandung Barat"}
  ]
  
  const districtOpt = [
    {value: "Cilame", label: "Cilame"}
  ]

  const groupOpt = [
    {value: "Group 1", label: "Group 1"}
  ]

  return (
    <Fragment>
      <div className="md:flex items-center justify-between">
        <TitleText className="flex-1">
          Detail Perencanaan: {date ?? "-"}
        </TitleText>
        {isFilterActive ? (
          <Button
            onClick={() => setIsFilterActive(false)}
            className="text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            size="md"
            startIcon={<BiFilterAlt />}
            endIcon={<BiChevronUp size={20} />}
          >
            Sembunyikan Filter
          </Button>
        ) : (
          <Button
            onClick={() => setIsFilterActive(true)}
            className="text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
            size="md"
            startIcon={<BiFilterAlt />}
            endIcon={<BiChevronDown size={20} />}
          >
            Lihat Filter
          </Button>
        )}
      </div>

      {/* Filter */}
      {isFilterActive && (
        <div className="flex flex-col gap-4">
          <div className="flex w-full gap-4">
            <div className="flex flex-col w-1/5 gap-2">
              <Label>NAP</Label>
              <InputForm name="nap" placeholder="Masukkan NAP" className="w-full" />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Provinsi</Label>
              <CustomSelectForm
                name="province"
                placeholder="Pilih salah satu"
                options={provinceOpt}
                className="w-full"
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Kabupaten/Kota</Label>
              <CustomSelectForm
                name="city"
                placeholder="Pilih salah satu"
                options={cityOpt}
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Kecamatan</Label>
              <CustomSelectForm
                name="district"
                placeholder="Pilih salah satu"
                options={districtOpt}
              />
            </div>
            <div className="flex flex-col w-1/5 gap-2">
              <Label>Group</Label>
              <CustomSelectForm
                name="group"
                placeholder="Pilih salah satu"
                options={groupOpt}
              />
            </div>
          </div>
          <div className="flex w-full justify-between gap-3">
            <Button
              className="flex-1 bg-white text-gray-700 border-[#D0D5DD] hover:text-white"
              size="md"
            >
              Hapus
            </Button>
            <Button
              className="flex-1 text-white bg-primary-600 border-primary-600 hover:bg-primary-700 hover:border-primary-700"
              size="md"
            >
              Terapkan Filter
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};    

export default PlanningFilter;
