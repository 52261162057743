import ProgramProgress from "pages/Program/Progress";
import ProgramProgressDetail from "pages/Program/Progress/Detail";

const progressRoutes = [
  {
    routeType: "public",
    path: "/program-progress",
    element: ProgramProgress,
    exact: true,
    type: "canView",
    key: "program-progress",
  },
  {
    routeType: "public",
    path: "/program-progress/detail/:id",
    element: ProgramProgressDetail,
    exact: true,
    type: "canView",
    key: "program-progress-detail",
  },
];

export default progressRoutes;
