import config from "app/config";
import axiosInstance from "app/interceptors";

export const getLaporanAktifitasDetail = (id) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/cfp/activity-report/${id}/detail`
  );
};

export const getLaporanAktifitas = (params, signal) => {
  return axiosInstance.get(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/cfp/activity-report/list`,
    { params, signal }
  );
};

export const putUpdateStatusLaporan = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/cfp/activity-report/${params.id}/updateStatus`,
    params.payload
  );
};

export const putBulkStatusLaporan = (params) => {
  return axiosInstance.put(
    `${config.BASE_URL}/api/monitoring-evaluasi/v1/cfp/activity-report/updateStatus/bulk`,
    params.payload
  );
};
